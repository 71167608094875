/* Container for the entire blog content */
.container {
  max-width: 800px; /* Restrict the width for readability */
  margin: 0 auto; /* Center it on the page */
  padding: 30px 20px; /* Add some padding */
  font-family: "Arial", sans-serif;
  line-height: 1.8;
}

/* Header section */
.header {
  margin-bottom: 20px;
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px 20px;
  font-family: "Arial", sans-serif;
  justify-content: space-between; /* Add this to push the content evenly */
}

.headerLeft,
.headerRight {
  flex: 1; /* Each will take up equal width */
}

.headerLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.headerRightImage {
  border-radius: 2%;
}

.headerRight {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header .headerLeft h1 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 10px;
}

.headerRight img {
  max-width: 100%;
  height: auto; /* Ensures image scales properly */
}

.meta {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Author section */
.author {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}

.author img {
  width: 75px;
  height: 75px;
  border-radius: 50%;
}

.author p {
  margin-bottom: 0;
}

/* Blog content section */
.content {
  text-align: left;
}

.content h1,
.content h2 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.content p {
  margin-bottom: 20px;
  font-size: 1.125rem;
  color: #333;
}

/* Carousel */
.imageCarousel {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  padding: 10px 0;
}

.carouselImage {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.socialShare {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  margin-left: 10px; /* Adjust for spacing */
}

.icon {
  font-size: 20px;
  cursor: pointer;
  color: #666; /* Default icon color */
}

.icon:hover {
  color: #000; /* Change color on hover */
}

/* Responsive Design */
@media (max-width: 768px) {
  .container {
    padding: 20px;
  }

  .header h1 {
    font-size: 2rem;
  }

  .content p {
    font-size: 1rem;
  }
}

.thumbnailGallery {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
  margin: 20px 0;
}

.thumbnailImage {
  width: 150px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.thumbnailImage:hover {
  transform: scale(1.05);
}

.imageTextBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0;
  text-align: center;
}

.imageTextImage {
  width: 100%;
  max-width: 600px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.imageTextContent {
  margin-top: 10px;
  font-size: 1rem;
  line-height: 1.6;
  color: #333;
}

.author {
  display: flex;
  align-items: center;
  gap: 10px;
}

.authorImage {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.authorDetails {
  display: flex;
  flex-direction: column;
}

.authorName {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.videoContainer {
  margin: 20px 0;
  text-align: center;
}

.videoIframe {
  width: 100%;
  max-width: 560px;
  height: 315px;
  border: none;
}

.customEmbed {
  margin: 20px 0;
  text-align: center;
}

@media (max-width: 768px) {
  .imageTextBlock {
    flex-direction: column;
    text-align: center;
  }

  .imageTextImage {
    width: 100%;
    max-width: 100%;
  }

  .thumbnailImage {
    width: 100px;
  }

  .header {
    flex-direction: column;
    text-align: center;
  }

  .headerLeft {
    align-items: center;
  }
}
