.container {
  display: flex;
  justify-content: center;
  gap: 10px;
 
}

.mulbutton{
  font-size: 18px;
  font-weight: 500;
  line-height: 21.94px;
  color: #ff67a1 !important;
  border: 1px solid #ff67a1 !important;
  border-radius: 25px !important;
  text-transform: inherit !important;
  min-width: 170px !important;
}
@media screen and (max-width: 768px) and (min-width: 320px) {
 .container{
    flex-wrap: wrap  ;

  
 }
 .mulbutton{
  width: 30% !important;
  font-size: 14px !important;
  line-height: 17.07px;
}
}