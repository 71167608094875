/* Orders Page Container */

/* Header */
.orders-header h2 {
  margin: 0;
  font-size: 1.5em;
}

/* Filters (Chips) */
.order-filters {
  margin: 20px 0;
}

.chip {
  padding: 10px 20px;
  margin-right: 10px;
  border-radius: 25px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
  font-weight: bold;
  font-size: 0.9em;
}

.chip.active,
.chip:hover {
  background-color: #d1b3ff;
  color: #fff;
  border-color: #d1b3ff;
}

/* Orders List */
.orders-list {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Adds space between each card */
  max-width: 100%; /* Ensure it fits within the parent container */
}

/* Order Card */
.order-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.order-info {
  display: flex;
  flex-direction: column;
}

.order-id {
  color: #ae69ff;
  font-weight: bold;
  margin-bottom: 5px;
}

.order-name {
  color: #333;
}

.order-meta {
  text-align: right;
}

.order-date {
  color: #888;
  margin-bottom: 5px;
}

.order-price {
  color: green;
  font-weight: bold;
  font-size: 1.2em;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.page-button {
  padding: 8px 12px;
  margin: 0 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #ffffff;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.page-button.active {
  background-color: #d1b3ff;
  color: #fff;
  border-color: #d1b3ff;
}

.page-button:hover {
  background-color: #e1b0ff;
  color: #fff;
}

.join-text {
  color: #333;
}

.link-button {
  font-size: 15px !important;
  cursor: pointer;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .orders-page {
    padding: 0 3px;
  }
}
