.expertContainer {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  padding: 20px;
  border-radius: 8px;
  color: white;
  flex-direction: column;
}

.expertImageSection {
  flex: 1;
  text-align: center; /* Centers the image and the text in the section */
  position: relative; /* Needed for absolute positioning of elements inside */
  padding-top: 3%;
}

.expertImageSection h3 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 0px;
}

.svgBackground {
  position: absolute;
  padding-top: 13%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 56%; /* Adjust the width as needed */
  height: auto; /* Keep aspect ratio */
  z-index: 0;
}

.expertImage {
  width: 60%; /* Adjust as needed */
  border-radius: 50%;
  position: relative;
  z-index: 1; /* Ensure image is above the SVG */
}

.expertDetails {
  flex: 2;
  align-items: center;
  padding: 10px;
  font-size: x-large;
}

.expertDetails h2 {
  font-weight: 800;
  font-size: 50px;
  background-color: #a490fe;
  display: inline-block;
  padding: 15px;
}

.stats {
  list-style: none; /* Removes default list styling */
  padding: 0;
  margin: 0 0 20px 0; /* Spacing below the list */
}

.stats li {
  display: flex;
  align-items: center;
  margin-bottom: 5px; /* Space between list items */
  font-weight: 600;
}

.stats img {
  width: 35px; /* Adjust this value as needed */
  height: 35px; /* Adjust this value as needed */
  margin-right: 15px; /* Space between the icon and text */
  vertical-align: middle; /* Aligns the icon with the text vertically */
}

.allActionBtn {
  background-color: #ff67a1 !important;
  font-weight: 600 !important;
  flex: 0 1 auto;
  font-family: "Poppins", sans-serif !important;
  font-size: 25px !important;
  box-shadow: 0px 5px 7px 0 rgba(0, 0, 0, 0.3) !important;
  text-transform: none !important;
  border-radius: 10px !important;
}

.greyBtn {
  background-color: rgb(186, 184, 184) !important;
  font-weight: 600 !important;
  flex: 0 1 auto;
  font-family: "Poppins", sans-serif !important;
  font-size: 30px !important;
  box-shadow: 0px 5px 7px 0 rgba(0, 0, 0, 0.3) !important;
  text-transform: none !important;
  border-radius: 10px !important;
}

.allActionBtn :hover {
  box-shadow:
    0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.singleExpertSection {
  display: flex;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .expertContainer {
    flex-direction: column;
    align-items: center; /* Center-aligns the items for a stacked layout */
  }

  .expertImageSection,
  .expertDetails {
    width: 100%;
    text-align: center;
  }

  .expertImage {
    width: 65%;
    max-width: 90%; /* Adjust image size on mobile */
  }

  .expertDetails h2 {
    font-size: 35px;
  }

  .expertDetails p {
    font-size: 20px;
  }

  .stats {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 5%;
  }

  .stats li {
    font-weight: 500;
  }

  .singleExpertSection {
    flex-direction: column;
  }
}
