/* Top section  */

.expertHeader {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 40px 0;
}

.containerLeft {
  width: 40%;
}

.bgHeart {
  position: absolute;
  z-index: -1;
  top: 0;
}
.bgwithcolortext {
  & span {
    background-color: #fff4f4;
    color: #ff67a1;
    font-size: 22px;
    font-weight: 500;
    padding: 5px 30px;
  }
}

.allActionBtn {
  background-color: #ff67a1 !important;
  font-weight: 600 !important;
  flex: 0 1 auto;
  font-family: "Poppins", sans-serif !important;
  font-size: 30px !important;
  box-shadow: 0px 5px 7px 0 rgba(0, 0, 0, 0.3) !important;
  text-transform: none !important;
  border-radius: 10px !important;
}

.greyBtn {
  background-color: rgb(186, 184, 184) !important;
  font-weight: 600 !important;
  flex: 0 1 auto;
  font-family: "Poppins", sans-serif !important;
  font-size: 30px !important;
  box-shadow: 0px 5px 7px 0 rgba(0, 0, 0, 0.3) !important;
  text-transform: none !important;
  border-radius: 10px !important;
}

.expertName {
  color: #602ef4;
  font-size: 55px;
  font-weight: 600;
}

.expertCol {
  font-size: 22px;
  font-weight: 500;
  color: #602ef4;
}

.expertDesc {
  font-size: 22px;
  font-weight: 400;
  color: #2d2d2d;
}

.containerRight {
  width: 35%;
}

.imgContainer {
  width: 100%;
  position: relative;

  & img {
    width: 100%;
  }

  & .expertImg {
    position: absolute;
    top: 57px;
    z-index: 999;
    left: 67px;
    border-radius: 50%;
    overflow: hidden;
    height: 80%;
    width: 80%;
  }
}

/* child Beheviour */

.childbeheviour {
  display: flex;
  position: relative;
  width: 80%;
  margin: 80px auto;
  /* gap: 30px; */
  justify-content: space-evenly;
  align-items: center;
}

.childleftContainer {
  width: 30%;
  position: relative;
  border-radius: 10px;
  /* overflow: hidden; */
}

.childleftContainer::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #f9f2ff;
  position: absolute;
  z-index: -1;
  top: -15px;
  left: -15px;
  border-radius: 10px;
}

.childRightContainer {
  width: 60%;
}
.innerVideoDisplay {
  width: 100% !important;
  /* height: 500px !important; */
  /* height: 600px !important; */

  & video {
    height: 600px !important;
  }
}

.secondheading {
  font-size: 44px;
  font-weight: 600;
  color: #7a53ff;
}

.ChildDescription {
  font-size: 22px;
  font-weight: 400;
  color: #2d2d2d;
}

/* counterBox section  */

.countersboxContainer {
  width: 80%;
  margin: 40px auto;
}

.counterBox {
  background: linear-gradient(92.66deg, #602ef4 1.8%, #b057da 102.02%);
  display: flex;
  justify-content: space-around;
  border-radius: 10px;
}

.cBox {
  color: #fff;
  text-align: center;

  & h2 {
    font-size: 55px;
    font-weight: 700;
    margin: 10px 0;
  }

  & p {
    font-size: 22px;
    font-weight: 400;
  }
}

/* Work Shop */

.workShopsContainer {
  background-image: url("../../Assets/expert_page/workshopBackgroung.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.WSContainer {
  width: 1400px;
  margin: 0 auto;
  padding-top: 190px;
  padding-bottom: 20px;
}

.wstopText {
  & span {
    background-color: #a490fe;
    color: #ffffff;
    font-size: 22px;
    font-weight: 500;
    padding: 5px 10px;
  }
}

.shopsName {
  font-size: 55px;
  font-weight: 600;
  color: #fff;
}

.workSContainer {
  font-size: 22px;
  font-weight: 400;
  color: #ffffff;
  text-align: justify;
}

.workSslider {
  background-image: url("../../Assets/expert_page/workshopbg_2.svg");
  padding-bottom: 150px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.InnerSliderCon {
  width: 1400px;
  margin: 0 auto;
  /* display: flex; */
  gap: 10px;
}

/* what client says */

.clientReviews {
  width: 1400px;
  margin: 0 auto;
  margin-left: 6%;
}
.CRHeading {
  font-size: 40px;
  font-weight: 800;
  color: #602ef4;
}

/* Specialities */

.specialityContainer {
  width: 90%;
  margin: 40px auto;
}

.spHeading {
  font-size: 55px;
  font-weight: 600;
  color: #7a53ff;
}

.spDesc {
  font-size: 22px;
  font-weight: 400;
  color: #7a53ff;
}

/* Blogs */

.BlogContainer {
  width: 1400px;
  margin: 0 auto;
}
.blogheading {
  font-size: 44px;
  font-weight: 800;
  color: #602ef4;
  text-align: center;
}

.onlyforbg {
  background: linear-gradient(
    180deg,
    rgba(244, 229, 255, 0.6) 0%,
    rgba(244, 229, 255, 0) 100%
  );
}
.innerImageDisplay {
  width: 100%;
  border-radius: 10px;
}

.expertPackages {
  padding: 40px 20px;
  background-color: #f9fafb;
  text-align: center;
}

.expertheading {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 16px;
}

.expertdescription {
  color: #6b7280;
  margin-bottom: 32px;
  font-size: 18px;
}

.packagesGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 34px;
  justify-content: center;
  align-items: stretch;
  margin: 0 auto;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  /* top section  */
  .expertHeader {
    display: block;
    width: 100%;
  }

  .containerLeft {
    width: 100%;
    text-align: center;
  }

  .containerRight {
    width: 90%;
    margin: 30px auto;
  }

  .bgwithcolortext {
    & span {
      font-size: 20px;
      font-weight: 500;
      padding: 5px 30px;
    }
  }

  .expertName {
    font-size: 25px;
  }

  .expertCol {
    font-size: 14px;
    font-weight: 600;
  }

  .expertDesc {
    font-size: 18px;
    font-weight: 400;
    padding: 0px 20px;
  }

  .bgHeart {
    width: 100px;
    top: 35%;
    right: 0;

    & img {
      width: 100%;
    }
  }

  .packagesGrid {
    grid-template-columns: 1fr;
  }

  /* child beheviour */

  .childbeheviour {
    display: block;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 2%;
  }

  .childleftContainer {
    width: 100%;
  }

  .childRightContainer {
    width: 100%;
    margin-top: 50px;
  }

  .secondheading {
    font-size: 20px;
    font-weight: 600;
  }
  .ChildDescription {
    font-size: 20px;
    font-weight: 400;
  }

  /* counter box */

  .countersboxContainer {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }

  .counterBox {
    flex-wrap: wrap;
  }

  .cBox {
    width: 150px;

    & h2 {
      font-size: 30px;
      font-weight: 700;
    }

    & p {
      font-size: 16px;
      font-weight: 400;
    }
  }

  /* workshop  */
  .workShopsContainer {
    background-image: url("../../Assets/expert_page/childbgmtop.svg");
  }

  .WSContainer {
    width: 100%;
    padding: 160px 20px 10px;
    box-sizing: border-box;
    margin-bottom: -3px;
  }

  .InnerSliderCon {
    width: 100%;
  }

  .wstopText {
    font-size: 18px;
    font-weight: 500;
  }

  .shopsName {
    font-size: 20px;
    font-weight: 600;
  }

  .workSContainer {
    font-size: 14px;
    font-weight: 400;
  }

  .workSslider {
    background-image: url("../../Assets/expert_page/childbgmbottom.svg");
  }

  /* client reviews */

  .clientReviews {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    margin-left: 0;
  }

  .CRHeading {
    font-size: 25px;
    font-weight: 800;
  }

  /* specialities */

  .wstopText {
    font-size: 16px;
  }

  .spHeading {
    font-size: 20px;
  }

  .spDesc {
    font-size: 14px;
    text-align: justify;
  }

  .spSliderCOntainer {
    width: 100%;
  }
  /* blog */

  .BlogContainer {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }

  .blogheading {
    font-size: 20px;
  }
  .expertImg {
    transform: translate(-30px, -22px);
  }

  .allActionBtn {
    font-size: 24px !important;
    padding: 6px 16px !important;
  }
}

@media only screen and (max-width: 1140px) and (min-width: 768px) {
  .expertImg {
    transform: translate(-32px, -25px);
  }
}

@media only screen and (max-width: 1400px) and (min-width: 996px) {
  .clientReviews {
    width: 100%;
  }
  .countersboxContainer {
    width: 90% !important;
  }
  .childbeheviour {
    width: 90%;
  }
  .childleftContainer {
    width: 30%;
  }
  .childRightContainer {
    width: 55%;
  }
  .BlogContainer {
    width: 100%;
  }
  .WSContainer {
    width: 100%;

    padding: 205px 20px 20px;
    box-sizing: border-box;
  }
  .InnerSliderCon {
    width: 100%;
    padding: 0px 12px;
    box-sizing: border-box;
  }
  .CRHeading {
    width: 96%;
    margin-left: auto;
  }
  .expertImg {
    transform: translate(-20px, -15px);
  }
}
