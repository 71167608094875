.account-delete-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.account-delete-card {
  background-color: white;
  padding: 30px;
  border-radius: 12px;
  width: 400px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.account-delete-card h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
}

.account-delete-card p {
  margin-bottom: 15px;
  color: #333;
}

.delete-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.actions {
  display: flex;
  justify-content: space-between;
}

.cancel-button,
.confirm-button,
.disabled-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.cancel-button {
  background-color: #ccc;
  color: #fff;
}

.confirm-button {
  background-color: red;
  color: white;
}

.disabled-button {
  background-color: grey;
  color: white;
  cursor: not-allowed;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .account-delete-card {
    width: 100%;
  }
}
