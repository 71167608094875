/* .Slider {
  position: relative;
  width: 100%;

  opacity: 0px;
  & img {
    width: 100%;
  }
  .download {
    position: absolute;

    bottom: 10%;
    left: 120px;

    button {
      background: #ff67a1;
      border-radius: 10px;
      box-shadow: 0px 6px 10px 0px #00000040;
      padding: 10px 25px;

      border: none;

      font-size: 20px;
      font-weight: 500;
      line-height: 24.38px;

      color: #ffffff;
    }
  }
}
.mobile {
  display: none;
}
.desktop {
  display: block;
}
.customnextarrow {
  position: absolute;

  right: 54px;
  top: 50%;

  z-index: 99;
}
.customprevarrow {
  position: absolute;

  left: 54px;
  top: 50%;

  z-index: 99;
}
.customarrow {
  border-radius: 50%;
  background-color: #bababa2b;
  border: none;
  color: #7a53ff;
  width: 50px;
  height: 50px;
} */
.ourExperts {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0px;
  & h1 {
    color: #602ef4;

    font-size: 44px;
    font-weight: 800;
    line-height: 53.64px;
  }
}
.buttons {
  display: flex;
  gap: 10px;
}

.innerButton {
  color: #ff67a1;
  background-color: #ffffff;
  border-radius: 25px;
  /* text-transform: inherit; */
  white-space: nowrap;
  border: 1px solid #ff67a1;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.94px;
  padding: 10px;
  justify-content: center;
  display: flex;
}
.expertCards {
  width: 80%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 35px;
}
@media only screen and (max-width: 992px) and (min-width: 768px) {
  .buttons {
    overflow: auto;
    width: 90%;
    margin: 0 auto;
  }

  .innerButton {
    width: 200px;
  }
}

@media screen and (max-width: 768px) and (min-width: 320px) {
  .download {
    width: 100%;
    text-align: center;
    left: 0px !important;
  }
  .ourExperts {
    font-size: 20px;
    font-weight: 800;
    line-height: 24.38px;

   & h1 {
      font-size: 20px;
    }
  }

  .buttons {
    overflow: auto;
    width: 90%;
    margin: 0 auto;
  }

  .innerButton {
    width: 200px;
    margin: 0 0 20px;
  }
  .expertCards {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
/* @media screen and (max-width: 550px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
} */
