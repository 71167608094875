

@media only screen and (max-width: 1140px) and (min-width: 768px) {


.container{
    width: 90%;
    margin: auto;

}


}