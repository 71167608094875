.container{
    width: 600px;
  border-radius: 15px;
}
.about{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    /* padding: 30px; */
    justify-content: space-evenly;
    width: 320px;
    margin:30px auto;
    position: relative;
}
.close{
    text-align: end;
    cursor: pointer;
}
.btn{
    width: 100%;
font-size: 14px;
font-weight: 400;
background-color: #FF67A1;
color: #FFFFFF;
text-transform: inherit;
padding: 10px;
}
.head{
font-size: 20px;
font-weight: 700;
color: #000000CC;
}
.desc{
font-size: 14px;
font-weight: 500;


}
.text{
text-align: center;


}
.image1{
    position: absolute;
    top: -25px;
    right: -30px; 
}
.image2{
    position: absolute;
    left: -70px;
    bottom: 40px;
}