.coachingCard {
  max-width: 450px;
  background-color: white;
  border-radius: 16px; /* Rounded corners */
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05); /* Subtle shadow */
  border: 1px solid #e5e7eb; /* Light border */
  margin-bottom: 5%;
}

/* Responsive styling */
@media (max-width: 767px) {
  .coachingCard {
    padding: 16px; /* Reduce padding for smaller screens */
    max-width: 100%; /* Ensure full width on smaller devices */
  }
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.rating {
  display: flex;
  align-items: center;
  gap: 4px; /* Space between star icon and rating text */
}

.starIcon {
  width: 18px;
  height: 18px;
  color: #fbbf24;
  fill: currentColor;
}

.tag {
  background-color: #e0f2fe;
  color: #2563eb;
  font-size: 12px;
  font-weight: 600;
  padding: 4px 8px;
  border-radius: 9999px; /* Fully rounded */
}

.title {
  font-size: 20px; /* Slightly smaller for cleaner look */
  font-weight: 600; /* Medium weight for hierarchy */
  color: #111827;
  margin-bottom: 8px;
  line-height: 1.4; /* Improved readability */
}

.subtitle {
  font-size: 14px;
  color: #6b7280;
  margin-bottom: 16px;
  line-height: 1.5; /* Better text spacing */
}

.readMore {
  color: #2563eb; /* Highlight for "Read more" */
  font-weight: 500;
  cursor: pointer;
}

.cardFooter {
  background-color: #f9fafb; /* Light background for footer */
  padding: 12px 16px; /* Comfortable padding */
  border-radius: 12px;
  display: flex;
  justify-content: space-between; /* Space out left and right content */
  align-items: center; /* Align vertically */
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
  gap: 16px;
}

.sessionInfo {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
}

.sessionInfo img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.sessionInfoFooter {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.sessionType {
  font-size: 14px;
  color: #6b7280;
  font-weight: 500;
}

.originalPrice {
  font-size: 14px;
  color: #9ca3af;
  text-decoration: line-through;
}

.discount {
  font-size: 15px;
  font-weight: 600;
}

.priceButton {
  display: flex;
  align-items: center;
  background-color: white; /* White background for price */
  padding: 8px 12px;
  border-radius: 12px; /* Rounded button */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Button shadow */
  font-size: 18px;
  font-weight: 700;
  gap: 8px;
  cursor: pointer;
  color: #111827; /* Button text color */
}

.priceSectionRight {
  display: flex;
}

.priceButton svg {
  width: 20px;
  height: 20px;
  stroke: #111827; /* Dark icon color */
}

.discountSection {
  display: flex;
  flex-direction: column;
  text-align: right;
  gap: 4px;
  padding-right: 5px;
}

/* Adjustments for smaller screens */
@media (max-width: 767px) {
  .discountSection,
  .priceButton {
    font-size: 16px;
    padding: 6px 10px;
  }
}

.sessionCountContainer {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #374151;
}
