.session-data-card {
  max-width: 600px;
  margin: auto;
  padding: 40px;
  background: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow-y: auto;
  max-height: 80vh;
  margin-bottom: 5%;
  margin-top: 2%;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.session-data-card h3 {
  font-size: 16px;
  color: #7a53ff;
  margin-bottom: 20px;
}

.questions-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.question-item {
  display: flex;
  flex-direction: column;
}

.question-text {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.question-input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  resize: none;
}

.options-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
}

.option-label {
  font-size: 14px;
}

.submit-button {
  margin-top: 20px;
  padding: 10px;
  width: 100%;
  background-color: #7a53ff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.submit-button:hover {
  background-color: #5a3ad7;
}

.skipText {
  display: flex;
  align-self: center;
  cursor: pointer;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .session-data-card {
    margin-bottom: 30%;
  }
}
