.Slider {
  position: relative;
  width: 100%;

  
  & img {
    width: 100%;
  }
}
.download {
  position: absolute;
  bottom: 10%;
  left: 120px;
}
.downloadeApp {
  background: #ff67a1;
  border-radius: 10px;
  box-shadow: 0px 6px 10px 0px #00000040;
  padding: 10px 25px;
  border: none;
  font-size: 20px;
  font-weight: 500;
  line-height: 24.38px;
  color: #ffffff;
}
.mobile {
  display: none;
}
.desktop {
  display: block;
}
.customnextarrow {
  position: absolute;
  right: 54px;
  top: 50%;
  z-index: 99;
}
.customprevarrow {
  position: absolute;

  left: 54px;
  top: 50%;

  z-index: 99;
}
.customarrow {
  border-radius: 50%;
  background-color: #bababa2b;
  border: none;
  color: #7a53ff;
  width: 50px;
  height: 50px;
}
@media only screen and (max-width: 992px) and (min-width: 768px) {
  .buttons {
    overflow: auto;
    width: 90%;
    margin: 0 auto;
  }
  .innerButton {
    width: 200px;
  }
}

@media screen and (max-width: 768px) and (min-width: 320px) {
  .download {
    width: 100%;
    text-align: center;
    left: 0px !important;
  }
}
@media screen and (max-width: 550px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
}
