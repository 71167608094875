.account-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.account-header h2 {
  margin: 0;
  font-size: 1.5em;
}

.edit-icon {
  font-size: 1.2em;
  cursor: pointer;
}

.account-form {
  margin-top: 20px;
}

.account-form .form-group {
  margin-bottom: 15px;
}

.account-form input {
  width: 100%;
  padding: 8px;
  font-size: 1em;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  box-sizing: border-box;
}

.disabled-input {
  background-color: #f5f5f5;
  cursor: not-allowed;
  color: #999;
}

/* Button styling */
.button-1 {
  background-color: #ea4c89;
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  line-height: 20px;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 10px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: background-color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-1:hover,
.button-1:focus {
  background-color: #f082ac;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input {
  padding: 8px;
  font-size: 1em;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .account-container {
    padding: 0 15px;
  }
}
