.packageInfo {
  display: flex;
  flex-direction: column;
  gap: 8px; /* Space between title and details */
  padding-top: 5%;
}

.title {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  color: #111827; /* Darker text color */
  margin: 0;
}

.details {
  display: flex;
  align-items: center;
  gap: 8px; /* Space between items */
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.16px;
  font-weight: 500;
}

.detailItem {
  display: flex;
  align-items: center;
  gap: 4px; /* Space between icon and text */
}

.icon {
  width: 20px;
  height: 20px;
}

.separator {
  color: #d1d5db; /* Light gray separator */
}

.priceContainer {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space out the price card and tagline */
  margin-top: 16px;
}

.priceCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 8px 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  gap: 5px;
}

.originalPrice {
  font-size: 14px;
  color: #999;
  text-decoration: line-through;
  margin-bottom: 4px;
}

.discountedPrice {
  font-size: 18px;
  font-weight: 700;
  color: #333;
}

.tagline {
  background-color: #fff4e5;
  border-radius: 16px;
  padding: 4px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap; /* Prevent text wrapping */
}

.taglineText {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #ff9800;
  font-weight: 600;
}

.taglineIcon {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}

@media (max-width: 768px) {
  .details {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.16px;
    font-weight: 500;
  }
}
