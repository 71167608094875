.order-summary {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  width: 800px;
  height: 200px;
  margin: 20px auto;
  box-sizing: border-box;
}

.order-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  padding-bottom: 8px;
  margin-bottom: 8px;
}

.order-content {
  display: flex;
  justify-content: space-between;
  gap: 8%;
  height: calc(100% - 60px);
}

.order-info {
  font-size: 12px;
  flex: 1;
}

.order-info div:first-child {
  color: #555;
  margin-bottom: 2px;
}

.order-links a {
  color: #0066c0;
  text-decoration: none;
}

.product-info {
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;
}

.product-title-info {
  display: flex;
  flex-direction: column;
}

.product-name-subtitle {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5%;
}

.product-image {
  width: 80px;
  height: 80px;
  margin-right: 16px;
  border-radius: 8px;
  object-fit: cover;
}

.product-name {
  font-size: 18px;
  color: #7a53ff;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 5%;
}

.action-buttons {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
}

.view-order-button,
.book-remaining-session-button,
.feedback-order-button {
  white-space: nowrap;
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  width: 180px;
  text-align: center;
}

.view-order-button {
  background-color: #7a53ff;
  border: none;
  color: white;
}

.book-remaining-session-button {
  background-color: #fff;
  border: 1px solid #d5d9d9;
  color: black;
  font-weight: 500;
}

.feedback-order-button {
  background-color: #ff67a1;
  border: none;
  color: white;
}

@media (max-width: 820px) {
  .order-summary {
    width: 95%;
    height: auto;
  }

  .order-header {
    flex-wrap: wrap;
  }

  .order-content {
    flex-direction: column;
    height: auto;
  }

  .action-buttons {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}
