/* Sessions Page Container */

/* Header */
.sessions-header h2 {
  margin: 0;
  margin-bottom: 15px;
  font-size: 1.5em;
}

/* Sessions List */
.sessions-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Session Card */
.session-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.session-info {
  display: flex;
  flex-direction: column;
}

.session-id {
  color: #ae69ff;
  font-weight: bold;
  margin-bottom: 5px;
}

.session-expert,
.session-date,
.session-time {
  color: #333;
  margin-bottom: 5px;
  font-weight: 600;
}

.session-meta {
  text-align: right;
  display: flex;
  flex-direction: column;
}

.session-timer-label {
  font-weight: bold;
  color: #555;
  display: flex;
  justify-content: center;
  gap: 5px;
}

.session-timer-highlight {
  color: #ff4d79;
}

.session-timer-placeholder {
  display: flex;
  margin: 10px 0;
  font-weight: bold;
  color: #ae69ff;
  justify-content: center;
}

.join-now-button {
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  background-color: #ff4d79;
  color: white;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.join-now-button:hover {
  background-color: #ff3366;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.page-button {
  padding: 8px 12px;
  margin: 0 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #ffffff;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.page-button.active {
  background-color: #d1b3ff;
  color: #fff;
  border-color: #d1b3ff;
}

.page-button:hover {
  background-color: #e1b0ff;
  color: #fff;
}

.sessions-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  flex-direction: column;
}

.toggle-container {
  display: flex;
  align-items: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  margin-left: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #ff4d79;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.heading {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
}

.upcoming-session-additional-info {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .session-card {
    flex-direction: column;
    align-items: flex-start;
  }

  .session-info {
    padding-bottom: 5px;
  }

  .session-meta {
    align-self: center;
  }
}
