.specilistCard {
  width: 85%;
  margin: auto;
  padding: 40px 0px;
}
.innerh1 {
  font-size: 25px;
  font-weight: 700;
  line-height: 30.48px;
  color: #263238;
  padding: 30px 0px;
}
.innerCards {
  width: 80%;
  margin: auto;
  padding: 30px 0px;
  & h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 24.38px;
    color: #b1b1b1;
    padding: 10px 0px;
  }
  & h2 {
    font-size: 30px;
    font-weight: 700;
    line-height: 36.57px;
    padding: 20px 0px;
  }
}
.innerCardContainer{
  display: flex;
  flex-direction: column;
  gap: 30px;
}
@media screen and (max-width: 768px) and (min-width: 320px) {
  .specilistCard {
    padding: 10px 0px;
  }
  .innerh1 {
    padding:20px 0px;
    font-size: 14px;
    line-height: 17.07px;
  }
  .innerCards{
    width: 90%;

    & h2{
      padding: 0px;
    }
  }
}
