.expert-details {
  display: flex;
  align-items: center;
  padding: 20px;
  flex-direction: column;
}

.expert-image-container {
  background-color: #f3e5f5;
  border-radius: 50%;
  display: inline-block;
}

.expert-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.expert-info {
  display: flex;
  flex-direction: column;
}

.expert-info h1 {
  font-size: 1.6rem;
  margin: 0;
  font-weight: 700;
}

.expert-qualification {
  font-size: 1rem;
  color: gray;
  display: flex;
  justify-content: center;
  margin: 5px;
}

.social-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.social-links a {
  margin-right: 10px;
  font-size: 1.2rem;
  color: #555;
  text-decoration: none;
}
