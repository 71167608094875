.container {
  width: 100%;

  display: flex;
  box-shadow: 2px 2px 8px 0px #00000040;
  background-color: #ffffff;
  justify-content: space-between;
}
.left {
  width: 45%;
  position: relative;
}
.label {
  position: absolute;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  background-color: #ffffff;
  top: 8px;
  left: 20px;
  padding: 5px;
}
.img {
  width: 100%;
  & img {
    width: 100%;
    display: block;
  }
}
.right {
  width: 55%;
  padding: 0px 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.top {
  display: flex;
  flex-direction: column;

  & h2 {
    font-size: 30px !important;
    font-weight: 700;
    line-height: 36.57px;
  }
  & span {
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    color: #727272;
  }
  & p {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    color: #a4a4a4;
  }
}
.readMore {
  align-self: flex-start;
  margin: 30px 0px !important;
  color: #7a53ff !important;
}
.endIcon {
  color: #7a53ff;
}

@media screen and (max-width: 768px) and (min-width: 320px) {
  .container {
    flex-direction: column;
  }

  .left {
    width: 100%;
  }
  .right {
    width: 95%;
    padding: 0px 15px;
  }
  .top {
    & h2 {
      font-size: 14px !important;
      padding: 0px;
    }
    & span {
      font-size: 10px;
    }
    & p {
      font-size: 12px;
    }
  }
  .readMore{
    margin: 10px 0px !important;
  }
}
