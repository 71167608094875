.revenue-dashboard {
  padding: 24px;
  font-family: Arial, sans-serif;
  max-width: 100%;
  overflow-x: hidden;
}

.dashboard-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
}

.tab-container {
  display: flex;
  border-bottom: 1px solid #e5e7eb;
  margin-bottom: 16px;
  max-width: 100%;
}

.tab {
  padding: 8px 16px;
  background: none;
  border: none;
  cursor: pointer;
  color: #6b7280;
  font-weight: 600;
}

.tab.active {
  border-bottom: 2px solid #7a53ff;
  color: #7a53ff;
}

.filters {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  flex-wrap: wrap;
  max-width: 100%;
}

.search-container {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #f3f4f6;
  border-radius: 8px;
  padding: 8px;
  width: 100%;
  max-width: 400px;
}

.search-input-container {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: white;
  border-radius: 4px;
  padding: 4px 8px;
  width: 100%;
}

.search-container select,
.search-input-container input,
.date-range select {
  background: transparent;
  border: none;
  padding: 8px;
  font-size: 14px;
  outline: none;
  width: 100%;
}

.search-icon,
.calendar-icon {
  color: #6b7280;
}

.date-range {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #f3f4f6;
  border-radius: 8px;
  padding: 8px;
  max-width: 100%;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container input {
  padding: 8px;
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid #d1d5db;
  outline: none;
  cursor: pointer;
  color: #111827;
}

.react-datepicker__input-container input::placeholder {
  color: #9ca3af;
}

.react-datepicker {
  border-radius: 12px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid #e5e7eb;
}

.react-datepicker__header {
  background-color: #f9fafb;
  border-bottom: 1px solid #e5e7eb;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #111827;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range {
  background-color: #7a53ff;
  color: white;
}

.react-datepicker__day--keyboard-selected {
  background-color: #7a53ff;
  color: white;
}

.react-datepicker__day:hover {
  background-color: #dbeafe;
}

.table-container {
  padding: 24px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 16px;
  max-width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}

.revenue-table {
  width: 100%;
  min-width: 600px;
  border-collapse: collapse;
  margin-top: 16px;
}

.revenue-table th,
.revenue-table td {
  padding: 12px 8px;
  text-align: left;
  border-bottom: 1px solid #e5e7eb;
  font-size: 14px;
}

.revenue-table th {
  background-color: #f9fafb;
  font-weight: 600;
}

.status-tag {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  display: inline-block;
}

.status-tag.paid {
  background-color: #d1fae5;
  color: #065f46;
}

.status-tag.failed {
  background-color: #fee2e2;
  color: #991b1b;
}

.status-tag.pending {
  background-color: #fef3c7;
  color: #92400e;
}

.paginationRevenue {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  flex-wrap: wrap;
  max-width: 100%;
}

.paginationRevenue button {
  padding: 8px 12px;
  border: 1px solid #d1d5db;
  background-color: white;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 8px;
  font-size: 14px;
}

.paginationRevenue button:hover {
  background-color: #f3f4f6;
}

.comingsoon-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
}

.comingsoon-text h1 {
  font-size: 30px;
  font-weight: 700;
  color: #7a53ff;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .revenue-dashboard {
    padding: 10px;
  }

  .filters {
    flex-direction: column;
    align-items: flex-start;
  }

  .date-range {
    margin-top: 8px;
    max-width: 300px;
  }

  .pagination {
    flex-direction: row;
    align-items: flex-start;
  }

  .pagination button {
    margin-left: 0;
    margin-top: 8px;
  }

  .table-container {
    padding: 16px;
  }

  .revenue-table {
    font-size: 12px;
    padding: 8px 4px;
  }

  .pagination {
    align-items: center;
    justify-content: center;
  }

  .pagination button {
    margin: 8px 0;
  }

  .paginationRevenue {
    justify-content: center;
  }

  .search-container select {
    width: auto;
  }
}
