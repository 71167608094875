.expert-about {
  padding: 20px 0px;
}

.expert-about h2 {
  font-size: 30px;
  margin-bottom: 10px;
  color: #602ef4;
}

.expert-about p {
  font-size: 16px;
  color: #444;
  line-height: 1.6;
  font-family: "Poppins", sans-serif;
}

.expert-about-package {
  display: none;
}

@media (max-width: 768px) {
  .expert-about h2 {
    text-align: center;
    font-size: 25px;
  }

  .expert-about p {
    text-align: center;
  }

  .expert-about-package {
    display: block;
  }
}
