.container{
    padding: 20px 0px;
}

.slick-dots {
    bottom: -40px;
}

@media screen and (max-width: 768px) and (min-width: 320px) {
 .container{
    padding: 0px;
 }
}