.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0px;
}
.img {
  width: 250px;
  & img {
    width: 100%;
  }
}
.desc {
  width: 100%;
  text-align: center;
  margin-top: -15px;
  & span {
    font-size: 20px;
    font-weight: 700;
    line-height: 24.38px;

    box-shadow: 0px 4px 4px 0px #00000026;
    padding: 10px;
    background-color: #ffffff;
  }
}
