html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.layout-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
}

.whatsapp-float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 70px;
  right: 20px;
  background-color: #25d366;
  color: white;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  transition: transform 0.3s;
}

.whatsapp-float:hover {
  transform: scale(1.1);
}

.whatsapp-float .fa-whatsapp {
  margin-top: 16px;
}

footer {
  flex-shrink: 0;
}
