.container{
    width: 88%;
    margin: auto;
    padding: 10px 0px;
    justify-content: space-between;
    display: flex;
}

@media only screen and (max-width: 992px) and (min-width: 768px) {
.container{
    flex-wrap: wrap;
}


}
@media screen and (max-width: 768px) and (min-width: 320px) {
 .container{
    flex-wrap: wrap;
    padding: 40px 0px;
 }
}

/* .btmstar{
    left: 261px;
    top: -60px;

   

}
.img{
    top: -4px;
    right: 56px;
    img{
        width: 200px;
    }
} */