.mainContainer{
    padding: 40px;
    box-sizing: border-box;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
    .mainContainer{
        padding: 20px;
        box-sizing: border-box;
    }
}