.container {
}
.title {
  font-size: 44px;
  font-weight: 800;
  line-height: 53.64px;
  color: #602ef4;
  text-align: center;
}

.headers {
  padding: 50px 0px;
}
.cards {
  width: 80%;
  margin: auto;
}

.clients {
  padding: 0px 20px;
  box-sizing: border-box;

  & p {
    font-size: 20px;
    font-weight: 400;
    line-height: 24.38px;
    color: #7a53ff;
    margin: 0px;
  }
  & h1 {
    font-size: 40px;
    font-weight: 800;
    line-height: 48.76px;
    margin: 0px;
    padding: 20px 0px;
    box-sizing: border-box;
  }
}
.blogs {
  width: 90%;
  margin: auto;
  padding: 50px 0px;
  box-sizing: border-box;
}

.ourclient {
  width: 90%;
  margin: auto;
  padding: 50px 0px;
  box-sizing: border-box;
}
.blogName {
  width: 100%;
  text-align: center;
  font-size: 30px;
  font-weight: 800;
  line-height: 36.57px;
}
.offer {
  position: relative;
}
.bgColor {
  background: linear-gradient(1deg, #f4e5ff 0%, rgba(244, 229, 255, 0) 100%);
}
@media screen and (max-width: 768px) and (min-width: 320px) {
  .title {
    font-size: 20px;
    line-height: 24.38px;
    padding: 20px 0px;
  }
  .cards{
    width: 90%;
  }
}
