.container {
}
.pregnancy {
  font-size: 44px;
  font-weight: 800;
  line-height: 53.64px;
  color: #602ef4;
  width: 100%;
  text-align: center;
}

.heads {
  padding: 40px 0px;
}

.cards {
  width: 80%;
  margin: auto;
}

.clients {
  padding: 0px 20px;
  box-sizing: border-box;
}
.clienth1 {
  font-size: 40px;
  font-weight: 800;
  line-height: 48.76px;
  margin: 0px;
  padding: 20px 0px;
  box-sizing: border-box;
}
.clientp {
  font-size: 20px;
  font-weight: 400;
  line-height: 24.38px;
  color: #7a53ff;
  margin: 0px;
}
.blogs {
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 50px 0px;
box-sizing: border-box;
}

.ourclient {
 width: 90%;
 margin: auto;
 padding: 50px 0px;
box-sizing: border-box;
}

.head {
  font-size: 30px;
  font-weight: 800;
  line-height: 36.57px;
  color: #000000;
  width: 100%;
  text-align: center;
}
.bgColor{
  background: linear-gradient(1deg, #F4E5FF 0%, rgba(244, 229, 255, 0) 100%);
}
@media screen and (max-width: 768px) and (min-width: 320px) {
  .container {
  }
  .pregnancy {
    font-size: 20px;
  }

  .head {
    font-size: 20px;
  }
  .blogs {
    width: 90%;
    margin: auto;
  }
  .ourclient {
    /* padding: 30px; */
  }
  .cards{
    width: 90%;
  }
}
