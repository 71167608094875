.promotionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 40px;
  background-color: #ffffff;
}

.textSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding-left: 8%;
}

.textSection h1 {
  font-size: 50px;
  font-weight: bold;
  line-height: 1.2;
  color: #000;
  margin: 0;
}

.textSection .highlight {
  color: #7a53ff;
}

.storeButtons {
  display: flex;
  gap: 15px;
}

.storeLink {
  display: inline-block;
}

.storeIcon {
  width: 160px;
  height: auto;
  transition: transform 0.2s ease;
}

.storeIcon:hover {
  transform: scale(1.05);
}

.tagline {
  font-size: 18px;
  line-height: 1.5;
  color: #602ef4;
  margin-top: 20px;
}

.tagline span {
  font-weight: bold;
  color: #7a53ff;
}

.imageSection {
  flex: 1;
  text-align: center;
}

.appScreens {
  max-width: 85%;
  height: auto;
}

@media (max-width: 767px) {
  .textSection h1 {
    font-size: 35px;
    text-align: center;
  }
  .promotionContainer {
    flex-direction: column;
  }
  .imageSection {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .appScreens {
    max-width: 90%;
    height: auto;
  }
}
