/* Container for the video player, ensures full width and responsiveness */
.video-player-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
}

/* Maintain the aspect ratio of 16:9 for all screen sizes */
.video-aspect-ratio {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* Maintain 16:9 aspect ratio */
  background-color: black;
}

/* Ensure the video element fully covers the container */
.video-element {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain; /* Maintain aspect ratio, avoid cropping */
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .video-player-container {
    width: 100%;
    max-width: 100%;
  }

  .video-aspect-ratio {
    padding-top: 56.25%; /* Keep the aspect ratio of 16:9 */
  }

  .video-element {
    object-fit: contain; /* Ensure the video scales properly */
  }
}
