.sectionContainer {
  margin-top: 3%;
  margin-bottom: 3%;
  text-align: center;
}

.bookingSessionPage {
  /* background: linear-gradient(135deg, #f3e5f5, #e8eaf6); */
  background: linear-gradient(
    to bottom,
    rgba(164, 144, 254, 0) 0%,
    #f3e5f5 50%,
    #e8eaf6 80%,
    rgba(122, 83, 255, 0) 100%
  );
  /* background: linear-gradient(
    to bottom,
    rgba(164, 144, 254, 0) 0%,
    #a490fe 50%,
    #7a53ff 80%,
    rgba(122, 83, 255, 0) 100%
  ); */
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  flex-direction: column;
}

.card {
  margin-top: 10px;
  margin-bottom: 60px;
  padding: 24px;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  width: 100%;
  box-sizing: border-box;
}

.divider {
  position: absolute;
  background-color: #ccc;
  z-index: 1;
}

.divider.vertical {
  display: block;
}

.contentContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-items: start;
  position: relative;
}

.contentContainer.centerAligned {
  display: flex;
  justify-content: center;
  align-items: center;
}

.leftSection {
  display: flex;
  flex-direction: column; /* Stack content vertically */
  gap: 20px; /* Gap between "Package Info" and "Expert About" */
}

.rightSection {
  grid-column: 2;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.CRHeading {
  font-size: 40px;
  font-weight: 800;
  color: #602ef4;
}

.leftSection[data-section="expert-about"] {
  display: none;
}

.noSlotsMessageSubtitle {
  font-size: 15px;
  color: #ff5c5c;
  margin: 20px auto;
  text-align: center;
}

.notAvailableContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.noSlotsMessage {
  font-size: 18px;
  color: #999;
  margin: 20px auto;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .contentContainer {
    display: flex;
    flex-direction: column;
  }

  .rightSection {
    order: -1;
  }

  .leftSection {
    order: 1;
  }

  .leftSection,
  .rightSection {
    width: 100%;
  }

  .divider.horizontal {
    width: 100%; /* Full width for horizontal divider */
    height: 1px;
    margin: 20px 0; /* Spacing between sections */
    position: relative; /* Ensure divider is within the flow */
  }

  .card {
    padding: 24px 10px;
    margin-top: 10px;
  }

  .bookingSessionPage {
    padding: 20px 0;
  }

  .CRHeading {
    font-size: 25px;
  }

  .leftSection[data-section="package-info"] {
    order: 1; /* PackageInfo at the top */
  }

  .divider.horizontal[data-section="divider-horizontal-1"] {
    order: 2;
  }

  .rightSection[data-section="date-availability"] {
    order: 3; /* DateAvailability comes after PackageInfo */
  }

  .divider.horizontal[data-section="divider-horizontal-2"] {
    order: 4;
  }

  .leftSection[data-section="expert-about"] {
    display: block;
    order: 5; /* ExpertAbout comes last */
  }

  .divider.vertical {
    display: none;
  }

  .leftSectionAbout {
    display: none;
  }
}
