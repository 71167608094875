.cardContainer {
  width: 350px ;
  box-shadow: 2px 2px 8px 0px #00000040;
  border-radius: 15px;
  margin: 20px auto;
  background-color: white;
  /* cursor: pointer; */
}

.imgContainer {
  width: 100%;
  position: relative;
  & img {
    width: 100%;
  }

  & span {
    position: absolute;
    top: 15px;
    left: 15px;
    background-color: #fff;
    color: #000;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 600;
    display: none;
  }
}


.blogdetails {
  color: #727272;
  font-size: 16px;
  font-weight: 700;
  padding: 10px 10px;
  box-sizing: border-box;
  & h5{
    margin: 0px;
    padding: 10px 0px;
  }
  & h4{
    margin: 0px;
    padding: 10px 0px;
  }
}

.blogName {
  font-size: 16px;
  font-weight: 700;
  color: #000;
}

.desc {
  font-size: 14px;
  font-weight: 400;
  color: #a4a4a4;
}
.btnContainer {
  display: flex;
  justify-content: space-between;
}
.readMore {
  color: #7a53ff !important ;
}
.readMoreIcon{
  color: #7a53ff ;
  cursor: pointer;

}

.shareIcon {
  color: #000 ;
}
.head {
  width: 100%;
  padding-left: 20px;
  box-sizing: border-box;
  h1 {
    font-size: 30px;
    font-weight: 800;
    line-height: 36.57px;
    color: #000000;
  }
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .cardContainer {
    width: 100%;
    margin:0px;

  }
  .head{
    text-align: center;
  }
}
