.contactuseForm {
    display: flex;
    width: 1400px;
    margin: 0 auto;
    justify-content: space-around;
  }
  
  .leftContainer {
    width: 40%;
  
    & div {
      margin: 20px 0;
      vertical-align: baseline;
    }
  }
  
  .heading {
    font-size: 30px;
    font-weight: 800;
    color: #7a53ff;
    text-align: center;
  }
  
  .iconText {
    display: flex;
    align-items: center;
    gap: 10px;
    & span {
      color: #7a53ff !important;
    }
  }
  .rightContainer {
    width: 40%;
  }
  
  .inputFields {
    margin: 20px 0;
  }
  .mapContainer {
    & img {
      width: 100%;
    }
  }
  .joinusLink {
    text-decoration: none;
    color: #000;
  }
  .submit{
    width: 100%;
    padding: 10px !important;
    background-color: #ff67a1 !important;
    font-weight: 500 !important;
    font-size: 16px !important;
  }
   .submit:hover{
      background-color: #FF67A1 !important;
   }
  @media only screen and (max-width: 767px) and (min-width: 320px) {
    .contactuseForm {
      width: 100%;
      display: block;
    }
  
    .leftContainer {
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
    }
    .mapContainer {
      width: 100%;
  
      & img {
        width: 100%;
      }
    }
  
    .rightContainer {
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
    }
  
    .heading {
      font-size: 20px;
    }
  
    .iconText {
      font-size: 14px;
    }
  }
  @media only screen and (max-width: 1400px) and (min-width: 996px) {
    .contactuseForm {
      width: 100%;
    }
  }
  