.cardContainer {
  width: 350px !important;
  background-color: #fff;
  box-shadow: 2px 2px 8px 0px #00000040;
  border-radius: 15px;
  overflow: hidden;
  margin: 20px;
  box-sizing: border-box;
  min-height: 375px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.imgContainer {
  max-height: 215px;
  overflow: hidden;
  width: 100%;
  & img {
    width: 100%;
  }
}

.detailsContainer {
  padding: 10px;
  box-sizing: border-box;
}
.allActionBtn {
  background-color: #ff67a1 !important;
}

.priceContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.expertName {
  font-size: 14px;
  font-weight: 600;
  color: #707070;
}

.PriceLeft {
  font-size: 20px;
  font-weight: 700;
  color: #602ef4;

  & span {
    font-size: 16px;
    font-weight: 700;
    color: #6b6b6b99;
  }
}
.slickstyles {
  width: 100%;
  box-sizing: border-box;
  /* width: 0 auto; */
}
.ratingcontainer{
  display: flex;
  align-items: center;
  gap: 5px;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .cardContainer {
    width: 95% !important;
    margin: 20px auto;
  }
}
