.not-found-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  padding: 20px;
}

.not-found-text {
  flex: 1;
  padding: 20px;
}

.not-found-text h1 {
  font-size: 96px;
  font-weight: 700;
  color: #7a53ff;
  margin-bottom: 20px;
}

.not-found-text h2 {
  font-size: 36px;
  font-weight: 500;
  color: #333;
  margin-bottom: 10px;
}

.not-found-text p {
  font-size: 18px;
  color: #555;
}

.not-found-image {
  flex: 1;
}

.not-found-image img {
  max-width: 100%;
  height: auto;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .not-found-container {
    flex-direction: column;
  }
}
