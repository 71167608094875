.congratsBanner {
  background-color: #f5f5ff;
  color: #8661f5;
  padding: 20px;
  border-radius: 10px;
  font-family: Arial, sans-serif;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.congratsBanner span {
  font-size: 24px;
}
