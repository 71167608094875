.footerContainer {
  background-image: url("../../Assets/footer/footerbg.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  position: relative;
}

.footerbottom {
  position: absolute;
  bottom: 0;
  width: 100%;

  & img {
    width: 100%;
  }
}

.copyrighttext {
  color: #fff;
  position: absolute;
  bottom: 10%;
  left: 10%;
  font-size: 18px;
  font-weight: 500;
}

.socialmediaLinks {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-direction: column;
}

.footerLinks {
  display: flex;
  justify-content: space-around;
  padding-top: 2%;
}

.socialmediaLinks {
  width: 20%;
}

.countactUs {
  width: 70%;
  display: flex;
  justify-content: space-around;
}

.footerBox {
  display: flex;
  align-items: center;
  gap: 20px;
  width: calc(100% / 3);

  & h5,
  h6 {
    color: #fff;
    margin: 5px 0;
  }

  & h6 {
    font-size: 14px;
    font-weight: 500;
  }
  & h5 {
    font-size: 14px;
    font-weight: 700;
    & a {
      text-decoration: none;
      color: #fff;
    }
  }
}

.fBformob {
  display: none;
}
.pageLink {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  display: none;
}

.socialIcon {
  display: flex;
  /* justify-content: space-between; */
  gap: 40px;
  align-items: center;
  cursor: pointer;
}

.plink {
  text-decoration: dashed;
  color: #fff;
  text-align: left;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .footerContainer {
    /* display: none; */
    background-image: url("../../Assets/footer/footerbgM.svg");
    height: 650px;
    margin-bottom: -20px;
    /* background-color: #602EF4; */
  }

  .footerLinks {
    flex-direction: column;
    padding-top: 7%;
  }

  .countactUs {
    flex-direction: column;
    /* width: 100%; */
    margin: 10px auto;
  }

  .socialmediaLinks {
    width: 100%;
    margin: 10px 0;
    flex-direction: row;
  }
  .hidemobile {
    display: none;
  }

  .footerBox {
    margin: 10px;
    width: 100%;
  }

  .footerbottom {
    display: none;
  }

  .fBformob {
    display: block;
    position: absolute;
    bottom: 0px;
    width: 100%;
    /* margin-bottom: -20px; */
    /* background-color: #602EF4; */
    & img {
      width: 100%;
    }
  }
  .pageLink {
    flex-direction: column;
    display: flex;
  }
}
