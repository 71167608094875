.container {
}

.dateListWrapper {
  position: relative;
  overflow: hidden;
  padding: 10px 0;
  border-radius: 8px;
}

.dateList {
  display: flex;
  gap: 10px;
  scroll-behavior: smooth;
  overflow-x: auto;
  padding: 5px 10px;
}

.dateList.no-gradient::after {
  display: none;
}

.dateItem {
  min-width: 70px;
  text-align: center;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #ddd;
  transition:
    background-color 0.3s,
    color 0.3s;
}

.dateItem.selected {
  background-color: #ff67a1;
  color: #fff;
  opacity: 1;
  border: none;
}

.dateAvialable {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.day {
  font-size: 14px;
  font-weight: bold;
}

.date {
  font-size: 16px;
  font-weight: bold;
}

.slots {
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  padding: 2px 8px;
  border-radius: 8px;
}

.selected .slots {
  color: white !important; /* Add a contrast color for text */
}

.slots.red {
  color: #d80027; /* Dark red */
}

.slots.yellow {
  color: #e6a700; /* Dark yellow */
}

.slots.green {
  color: #008f43; /* Dark green */
}

.scrollButton {
  width: 40px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition:
    background-color 0.3s,
    transform 0.2s;
}

.scrollButton:hover {
  background-color: #f5f5f5;
  transform: scale(1.1);
}

.scrollButton img {
  width: 16px;
  height: 16px;
}

.timePeriodContainer {
  display: flex;
  position: relative;
  width: 100%;
  background-color: #f2f2f2; /* Gray background */
  border-radius: 20px;
  padding: 10px 5px;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.timePeriodButton {
  flex: 1;
  background: transparent;
  border: none;
  text-align: center;
  cursor: pointer;
  position: relative;
  font-size: 14px;
  font-weight: 600;
  color: #444;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.timePeriodButton:hover {
  color: #000;
}

.active {
  color: #000;
}

.icon {
  width: 20px;
  height: 20px;
}

.slider {
  position: absolute;
  top: 5px;
  left: 0;
  width: calc(33.33% - 2px);
  height: calc(100% - 10px);
  background-color: #fff;
  border-radius: 20px;
  transition: transform 0.3s ease-in-out;
  z-index: 0;
}

.timePeriod {
  flex: 1;
  padding: 10px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  background-color: #f5f5f5;
  border: none;
  color: #333;
  transition:
    background-color 0.3s,
    color 0.3s;
}

.timePeriod.active {
  background-color: #ff67a1;
  color: white;
  font-weight: bold;
}

.timeSlots {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

/* Individual time slot styling */
.timeSlot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #ccc;
  cursor: pointer;
  font-size: 18px;
  color: #333;
  transition:
    background-color 0.3s,
    border-color 0.3s;
  margin-top: 2%;
}

.timeSlot.available:hover {
  border-color: #ff67a1;
}

.timeSlot.selected {
  background-color: #ff67a1;
  color: white;
  border: 1px solid #ff67a1;
  font-weight: bold;
}

.duration {
  font-size: 12px;
  margin-top: 4px;
  color: inherit;
}

/* No slots message styling */
.noSlotsMessage {
  font-size: 18px;
  color: #999;
  margin: 20px auto;
  text-align: center;
}

.proceedButton {
  width: 100%;
  padding: 15px;
  margin-top: 20px;
  background-color: #ff67a1;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
}

.proceedButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.dateAvialable {
  display: flex;
  flex-direction: column;
}

.dateHeading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dateHeadingButtons {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.dateHeading h3 {
  font-size: 20px;
  line-height: 22px;
  font-weight: 600;
  letter-spacing: 0.16px;
}

.container h3 {
  font-size: 20px;
  line-height: 22px;
  font-weight: 600;
  letter-spacing: 0.16px;
}

.dateTimeText {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.noSlotsMessageSubtitle {
  font-size: 15px;
  color: #ff5c5c;
  margin: 20px auto;
  text-align: center;
}

.notAvailableContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timeSlot.available {
  cursor: pointer;
}

.timeSlot.unavailable {
  cursor: not-allowed;
  color: #aaa; /* Gray text */
  background-color: #f5f5f5; /* Light gray background */
}

@media (max-width: 767px) {
}
