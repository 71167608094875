.desktopContainer {
  display: block;
}
.learnBabycare {
  width: 100%;
  padding: 20px 0px;
  position: relative;
  & img {
    width: 100%;
  }
}

.aboutBabies {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.left {
  position: relative;
  /* max-height:400px ; */
  cursor: pointer;
  width: 45%;
  & img {
    width: 100%;
  }
}
.left::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.6) -1.15%,
    rgba(0, 0, 0, 0) 77%
  );
  width: 100%;
  height: 100%;
  z-index: 2;
}

.desc {
  position: absolute;
  display: flex;
  bottom: 10px;
  align-items: flex-end;
  justify-content: space-around;
  width: 100%;
  z-index: 8;
  & h1 {
    position: absolute;
    bottom: 60px;
    left: 45px;
    width: 95%;
    font-size: 25px;
    font-weight: 700;
    line-height: 30.48px;
    color: #ffffff;
  }
}
.details {
  display: flex;
  align-items: center;
  gap: 10px;
  & p {
    font-size: 16px;
    font-weight: 300;
    line-height: 19.5px;
    color: #ffffff;
    margin: auto;
  }

  & span {
    font-size: 16px;
    font-weight: 500;
    line-height: 19.5px;
    color: #ffffff;
  }
}
.img {
  width: 60px;

  & img {
    width: 100%;
  }
}
.date {
  font-size: 14px;
  font-weight: 300;
  line-height: 17.07px;
  padding-bottom: 10px;
  color: #ffffff;
}
.watched {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 10px;
  & p {
    font-size: 14px;
    font-weight: 300;
    line-height: 17.07px;
    padding-bottom: 2px;
    color: #ffffff;
  }
}
.middle {
  width: 25%;
}
.right {
  width: 57%;
  display: flex;
  justify-content: space-evenly;
}
.btns {
  padding-top: 100px;
  box-sizing: border-box;
  overflow: auto;
  display: flex;
  gap: 20px;

  width: 100%;
  justify-content: center;

  & button {
    border: 1px solid #ff67a1;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.94px;
    color: #ff67a1;
    border-radius: 25px;
    text-transform: inherit;
    min-width: 200px;
  }
}
.btns::-webkit-scrollbar {
  /* display: none; Hide scrollbar for Chrome, Safari, and Opera */
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 70%;
  align-items: center;
  margin: auto;
  padding: 50px 0px;
  gap: 25px;
}

.mobileContainer {
  display: none;
}
.topHead {
  left: 20px;
  position: absolute;
  bottom: 25px;
  font-size: 60px;
  font-weight: 700;
  line-height: 73.14px;
  color: #ffffff;
  width: 25%;
}

.circularImage {
  width: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 768px) and (min-width: 320px) {
  .desktopContainer {
    display: none;
  }

  .mobileContainer {
    display: block;
  }

  .left {
    width: 90%;
    margin: auto;
  }
  .middle {
    width: 90%;
    display: flex;
    justify-content: center;
  }
  .right {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
  }

  .aboutBabies {
    flex-direction: column;
    gap: 25px;
  }
  .cards {
    width: 90%;
    margin: auto;
    padding: 50px 0px;
    gap: 20px;
  }

  .desc {
    bottom: -5px;
    padding: 10px;
    box-sizing: border-box;
    justify-content: space-between;
    & h1 {
      font-size: 14px;
      line-height: 17.07px;
      width: 80%;
      left: 20px;
      bottom: 90px;
    }
  }
  .details {
    & p {
      font-size: 12px;
      line-height: 14.63px;
    }
    & span {
      font-size: 12px;
      line-height: 14.63px;
    }
  }
  .details {
    position: absolute;
  }
  .baseline {
    width: 100%;
    display: flex;
    /* gap: 105px; */
    flex-direction: column;
    align-items: flex-end;
  }
  .date {
    font-size: 12px;
    white-space: nowrap;
    width: 100px;
    & p {
      margin: 5px 0;
    }
  }
  .watched {
    font-size: 12px;
    white-space: nowrap;
    width: 150px;
    & p {
      margin: 5px 0;
    }
  }
  .topHead {
    width: 54%;
    font-size: 30px;
    line-height: 36.57px;
  }
  .bottomTop {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
@media only screen and (max-width: 1140px) and (min-width: 768px) {
  .cards {
    width: 90%;
  }
  .middle {
    width: 40%;
  }
  .right {
    width: 85%;
  }
  .left {
    width: 80%;
  }
  .aboutBabies {
    justify-content: center;
    flex-wrap: wrap;
  }
}
