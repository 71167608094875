.desktopContainer {
  background-image: url("../../Assets/ServiceExpert.svg");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 205px 0px;
  position: relative;
  margin-top: -2px;
  display: block;
}

.weHave {
  width: 100%;
  position: absolute;
  text-align: center;
  top: 125px;
}
.h1 {
  margin: 0px;
  font-size: 40px;
  font-weight: 800;
  line-height: 48.76px;
  color: #ffffff;
  margin: 0px;
}
.expertList {
  width: 100%;
  justify-content: space-evenly;
  padding-top: 45px;
}
.mobileContainer {
  display: none;
  background-image: url("../../Assets/ExpertMobile.svg");
  /* background-size: contain; */
  background-size: cover;
  background-repeat: no-repeat;
  height: 560px;
  padding: 345px 0px;
  position: relative;
}
/* slider arrows css */
.slickstyles {
  position: relative;
}
.customarrow {
  background-color: rgba(0, 0, 0, 0.1);
  color: #fff;
  padding: 7px 10px;
  border: none;
  border-radius: 25px;
  z-index: 10;
}

.customprevarrow {
  position: absolute;
  top: 50%;
  left: 0;
}

.customnextarrow {
  position: absolute;
  top: 50%;
  right: 0;
}
@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .expertList {
    overflow-y: auto;

    gap: 20px;
  }
}

@media screen and (max-width: 768px) and (min-width: 320px) {
  .desktopContainer {
    display: none;
  }

  .mobileContainer {
    display: block;
    height: auto;
    padding: 350px 0px;
  }
  .expertList {
    overflow-y: auto;
    justify-content: normal;
    padding: 0px;
    gap: 15px;
    position: absolute;
    top: 200px;
  }
  .weHave {
    top: 40px;
  }
  .h1 {
    font-size: 20px;
  }
}
