/* Profile Page Container */
.profile-page {
  display: flex;
  margin: 20px;
  margin-bottom: 8%;
}

/* Left Card - Navigation */
.left-card {
  width: 250px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  border: 1px solid #dcdcdc;
}

.profile-photo img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.profile-name {
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 20px;
}

.nav-options {
  text-align: left;
}

.nav-option {
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 5px;
}

.nav-option i {
  margin-right: 10px;
  color: #333;
}

.nav-option.active,
.nav-option:hover {
  background-color: #e1b0ff;
  color: #fff;
}

.nav-option .dropdown-icon {
  margin-left: auto;
}

/* Right Card - Account Details */
.right-card {
  flex: 1;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-left: 20px;
  border: 1px solid #dcdcdc;
}

.session-sub-option {
  padding: 10px 20px;
  font-size: 0.9em;
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 5px;
  margin-left: 20px; /* Indentation for sub-option */
  margin-top: 5px;
  transition: background-color 0.3s;
}

.session-sub-option.active,
.session-sub-option:hover {
  background-color: #d1b3ff;
  color: #fff;
}

/* Update for Session Option when Expanded */
.nav-option.active {
  background-color: #e1b0ff;
  color: #fff;
}

.logout-option {
  margin-top: auto;
  cursor: pointer;
  color: red;
}

.logout-option:hover {
  background-color: #f8d7da; /* Slight background change on hover */
  color: darkred; /* Darker color on hover */
}

.animated-button-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.animated-gradient-button {
  background: linear-gradient(90deg, #ff6ec4, #7873f5);
  color: white;
  font-size: 1.2rem;
  padding: 12px 24px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background 0.3s ease;
  outline: none;
  position: relative;
  overflow: hidden;
  z-index: 0;
  font-weight: 500;
}

.animated-gradient-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 300%;
  height: 100%;
  background: linear-gradient(90deg, #7873f5, #ff6ec4, #7873f5);
  transition: 0.5s;
  z-index: -1;
}

.animated-gradient-button:hover::before {
  left: 0;
}

.animated-gradient-button span {
  position: relative;
  z-index: 1;
}

.animated-gradient-button:active {
  transform: scale(0.98);
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .left-card {
    display: none;
  }

  .right-card {
    margin: 0px;
    padding: 20px 0px 20px 0px;
    max-width: 100%;
  }

  .profile-page {
    margin: 0 0 20px 0;
  }
}
