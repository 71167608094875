.container {
  margin: auto;
  width: 95%;
  box-sizing: border-box;
}
.top {
  padding: 0px 25px;
  box-sizing: border-box;
 & span {
    background-color: #f4e5ff;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    padding: 8px 15px;
    color: #000000;
  }
}
.servicesp {
  font-size: 30px;
  font-weight: 800;
  line-height: 36.57px;
  color: #8b8b8b;

  margin: -50px 0px 20px -20px;
}
.servicesh1 {
  font-size: 40px;
  font-weight: 800;
  line-height: 48.76px;
  color: #7a53ff;
  margin: 0px;
  padding: 20px 0px;
  box-sizing: border-box;
}
@media screen and (max-width: 768px) and (min-width: 320px) {
  .container {
    padding: 0px;
  }
  .servicesp {
    font-size: 18px !important;
  }
  .servicesh1 {
    font-size: 20px !important;
  }
}
