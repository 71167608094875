.container {
  position: relative;
}

.experTop {
  background-image: url("../../Assets/wavesUp.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 316px;
}
.img {
  position: absolute;
  top: 31px;
  right: 117px;
}
.expetBottom {
  background-image: url("../../Assets/WavesDown.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  /* height: 880px; */
  min-height: 750px;
  position: relative;
}
.btmsum {
  bottom: 130px;
  position: absolute;
  right: 95px;
}
.btmstar {
  position: absolute;
  left: 685px;
  top: -56px;
}
.expetBottom {
  width: 100%;
}
.weHave {
  width: 100%;
  text-align: center;

}
.experth1{
  margin: 0px;
  font-size: 40px;
  font-weight: 800;
  line-height: 48.76px;
  color: #ffffff;
  margin: 0px;
}
.expertList {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-evenly;
  padding: 90px 25px;
  box-sizing: border-box;
}
.Details {
  padding: 65px;
}
.ourclient {
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 100px;
}
.clients {
  padding-left: 24px;
  padding-bottom: 19px;
  display: flex;
  flex-direction: column;
  gap: 10px;
 & p {
    font-size: 20px;
    font-weight: 400;
    line-height: 24.38px;
    color: #7a53ff;
    margin: 0px;
  }
 & h1 {
    font-size: 40px;
    font-weight: 800;
    line-height: 48.76px;
    margin: 0px;
  }
}

/* slider arrows css */
.slickstyles{
  position: relative;
}
.customarrow{
  background-color: rgba(0, 0, 0, 0.1);
  color: #fff;
  padding: 7px 10px;
  border: none;
  border-radius: 25px;
  z-index: 10;
}

.customprevarrow{
  position: absolute;
  top: 50%;
  left: 0;
  
}

.customnextarrow{
  position: absolute;
  top: 50%;
  right: 0;
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .expertList {
    overflow-y: auto;

    gap: 20px;
  }
  .Details {
    padding: 20px;
  }
  .btmsum {
    bottom: 85px;
    position: absolute;
    right: 50px;
  }
  .experTop {
    height: 187px;
  }
  .expetBottom {
    height: 840px;
    margin-top: -5px;
  }
  .btmstar {
    left: 262px;
  }
  .img {
    top: 0px !important;
    right: 79px !important;

   & img {
      width: 200px;
    }
  }
}

@media screen and (max-width: 768px) and (min-width: 320px) {
  .container {
    background-image: url("../../Assets/bgMiddle.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 365px;
    padding: 150px 0px;
  }

  .experTop {
    display: none;
   & img {
      display: none;
    }
  }
  .expetBottom {
    background-image: none;
    padding: 68px 0px;
  }
  .Details {
    padding: 0px;
    padding: 0px;
    gap: 15px;
  }

  .expertList {
    /* overflow-y: auto; */
    justify-content: normal;
    padding: 0px;
    gap: 15px;
    position: absolute;
    top: 10px;

  }
  .btmstar {
    display: none;
  }
  .btmsum {
    display: none;
  }
  .weHave {
    position: absolute;
    top: -82px;
   & h1 {
      font-size: 20px;
    }
  }
  .ourclient {
    padding-top: 80px;
    padding-bottom: 30px;

    padding-left: 20px;
  }

  .clients {
    gap: 0px;
  
    & p {
      font-size: 20px;
    }
  }
  .experth1{
    font-size: 16px;
  }

  .customprevarrow{
    left: 10px;
    top: 40%;

    
  }
  
  .customnextarrow{
    right: 10px;
    top: 40%;

  }
}

