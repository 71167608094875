.session-page {
  display: flex;
  flex-direction: column;
  min-height: 75vh;
  margin-bottom: 7%;
}

.session-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .session-page {
    margin-bottom: 30%;
  }
}
