.container {
  background-image: url("../../Assets/Servicebg.svg");
  width: 100%;
  height: 780px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: block;
}

.heading {
  position: absolute;
  top: 200px;
  left: 690px;
  right: 0px;
  font-size: 40px;
  font-weight: 800;
  line-height: 48.76px;
  color: #ffffff;
}
.slideImages {
  top: 350px;
  position: relative;
}

.mobileContainer {
  background-image: url("../../Assets/offerMobile.svg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 900px;
  display: none;
}
@media screen and (max-width: 768px) and (min-width: 320px) {
  .container {
    display: none;
  }
  .mobileContainer {
    display: block;
    height: 700px;
  }
  .slideImages {
    top: 200px;
  }
  .heading {
    font-size: 20px;
    top: 115px;
    left: 120px;
  }
}
@media only screen and (max-width: 1140px) and (min-width: 768px) {

.heading{
  left: 340px;
  top: 165px;
}
.container{
  margin-bottom: -90px;
  z-index: -1;
  height: 890px;
}

}