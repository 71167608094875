.container {
  background-image: url("../../Assets/babySleep.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 555px;
  display: block;
}
.topic {
  width: 40%;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 90px 0px;

  & input {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #00000099;
    background-color: #ffffff;
    border-radius: 8px;
  }
}
.h1 {
  font-size: 30px;
  font-weight: 700;
  line-height: 36.57px;
  color: #ffffff;
}
.recbutton {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #ffffff !important;
  background-color: #ff67a1 !important;
  padding: 15px !important;
  border-radius: 8px;
  border: none !important;
}
.bottom {
  display: flex;
  flex-direction: column;
  gap: 70px;
}
.mobileContainer {
  display: none;
  background-image: url("../../Assets/mobileSleepBaby.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 690px;
}

@media screen and (max-width: 768px) and (min-width: 320px) {
  .container {
    display: none;
  }
  .mobileContainer {
    display: block;
  }
  .topic {
    width: 80%;
    padding: 115px 0px;
    gap: 150px;
    input {
      padding: 25px;
    }
  }
  .h1 {
    font-size: 20px;
  }
  .recbutton {
    padding: 25px !important;
  }
}
