.container {
  display: flex;
  align-items: center;
  width: 90%;
  margin: auto;

  padding: 50px 0px;
}
.left {
  width: 35%;
}
.right {
  width: 60%;

  & span {
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
  }
}
.aboutbotton {
  color: #602ef4 !important;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  background-color: #ffffff !important;
  text-transform: inherit !important;
  border-radius: 0px !important;
  padding: 5px 20px !important;
}
.middle {
  padding: 20px 0px;
}
.p {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #602ef4;
  margin: 0px;
}
.h1 {
  color: #602ef4;
  font-size: 30px;
  font-weight: 700;
  line-height: 45px;
  margin: 0px;
}
@media only screen and (max-width: 1280px) and (min-width: 768px) {
  .left {
    & img {
      width: 100%;
    }
  }
}

@media screen and (max-width: 768px) and (min-width: 320px) {
  .container {
    flex-direction: column;
  }
  .left {
    width: auto;
    & img {
      width: 100%;
    }
  }
  .right {
    width: auto;

    & span {
      font-size: 14px;
    }
  }
  .p {
    font-size: 16px;
  }
}
