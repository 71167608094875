.container {
}
.services {
  padding: 20px;
  box-sizing: border-box;
}
.top {
  width: 96%;
  margin-left: auto;
  & span {
    background-color: #f4e5ff;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    padding: 8px 15px;
    color: #000000;
  }
}
.servicesh1 {
  font-size: 40px;
  font-weight: 800;
  line-height: 48.76px;
  color: #7a53ff;
  margin: 0px;
  padding: 20px 0px;
  box-sizing: border-box;
}

.bgColor {
  /* background: linear-gradient(
    180deg,
    rgba(244, 229, 255, 0) 0.02%,
    #f4e5ff 100%
  ); */
  background: linear-gradient(180deg, rgba(244, 229, 255, 0.04) 2.69%, #F4E5FF 33.19%, rgba(244, 229, 255, 0) 100%);

  padding: 30px 0px;
}

.Details {
  padding: 65px 0px;
}

.clients {
  padding: 0px 20px;
  box-sizing: border-box;

  & p {
    font-size: 20px;
    font-weight: 400;
    line-height: 24.38px;
    color: #7a53ff;
    margin: 0px;
  }
  & h1 {
    font-size: 40px;
    font-weight: 800;
    line-height: 48.76px;
    margin: 0px;
    padding: 20px 0px;
    box-sizing: border-box;
  }
}
.blogs {
  width: 90%;
  margin: auto;
  box-sizing: border-box;
  padding: 70px 0px;
}

.ourclient {
  width: 90%;
  margin: auto;
  padding: 50px 0px;
  box-sizing: border-box;
}
/* & span {
  font-size: 12px;
  font-weight: 300;
  line-height: 14.63px;
  color: #000000;
} */
.blogName {
  font-size: 30px;
  font-weight: 800;
  width: 95%;
  text-align: center;
  padding: 20px;
}

@media screen and (max-width: 768px) and (min-width: 320px) {
  .blogs {
    padding: 15px;

    box-sizing: border-box;
  }
  .services {
    padding: 0px;
  }
  .ourclient {
  }
  .Details {
    padding: 0px;
  }
  .servicesh1 {
    font-size: 20px !important;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .bgColor {
    margin-bottom: -30px;
  }
  .ourclient {
    width: 80%;
  }
}
