.cardContainer {
  width: 350px !important;
  background-color: #f6f6f6;
  box-shadow: 2px 2px 20px 0px #00000040;
  border-radius: 18px;
  padding: 40px 20px;

  box-sizing: border-box;
  text-align: center !important;
  margin: 20px auto;
}

.imgContainer {
  width: 150px;
  margin: 0 auto;

  & img {
    width: 100%;
  }
}

.reviewsContainer {
  position: relative;
  margin-top: 60px;
}

.bgquote {
  position: absolute;
  top: -35%;
  left: 0;
}

.slickstyles {
  width: 100%;
  box-sizing: border-box;
  /* width: 0 auto; */
}

/* slider arrows css */
.slickstyles{
  position: relative;
}
.customarrow{
  background-color: rgba(0, 0, 0, 0.1);
  color: #fff;
  padding: 7px 10px;
  border: none;
  border-radius: 25px;
  z-index: 10;
}

.customprevarrow{
  position: absolute;
  top: 50%;
  left: 0;
  
}

.customnextarrow{
  position: absolute;
  top: 50%;
  right: 0;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .cardContainer {
    /* width: 320px !important; */
    /* margin: 20px 10px; */
    width: auto !important;
  }
}
