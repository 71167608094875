.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;
  /* min-height: 530px; */
  min-height: 400px;
  width: 350px;
  margin: 0 auto;
  & h1 {
    font-size: 26.26px;
    font-weight: 600;
    line-height: 32.01px;
    margin: 0px;
    color: #ffffff;
  }
  & p {
    font-size: 18px;
    font-weight: 400;
    line-height: 21.94px;
    color: #ffffff;
  }
}
.img {
  width: 350px;
  height: 315px;
  max-height: 445px;
  overflow: hidden;
  position: relative;
  border-radius: 100px;
  & img {
    /* width: 100%; */
    height: 100%;
    /* position: relative; */
    /* z-index:4; */
  }
}

.expertImgCircle {
  width: 90%;
  margin: 0 auto;
  max-height: 315px;
  border-radius: 50%;
  /* border-radius: 25px; */
}

.bgCircle {
  width: 100%;
  max-height: 315px;
  position: absolute;
  bottom: 0;
  z-index: -1;
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .container {
  }
  .img {
    width: 281px !important;
    padding-left: 49px !important;
    & img {
      width: 100%;
    }
  }
}
@media screen and (max-width: 768px) and (min-width: 320px) {
  .container {
    min-height: 300px;
    margin: 20px 0;
    width: 100%;
    & h1 {
      font-size: 18px;
    }
    & p {
      font-size: 16px;
    }
  }
  .img {
    flex: 1;
    position: relative;
    padding-top: 3%;
    width: 100%;
  }

  .expertImgCircle {
    width: 65%;
    max-width: 90%;
    border-radius: 50%;
    position: relative;
    z-index: 1;
  }

  .bgCircle {
    position: absolute;
    padding-top: 6%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 58%; /* Adjust the width as needed */
    height: auto; /* Keep aspect ratio */
    z-index: 0;
  }
}
