.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Center the card vertically */
  background-color: #f0f0f0;
  background-image: url("../../Assets/Desktop.svg"); /* Background image */
  background-size: cover; /* Ensures the background covers the entire area */
  background-position: center; /* Centers the background image */
}

/* Logo Container */
.logoContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px; /* Space between logo and card */
}

.logo {
  width: 75%; /* Adjust logo size */
  height: auto;
}

.cardContainer {
  display: flex;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  max-width: 900px;
  width: 100%;
}

.cardImage {
  flex: 1;
  background-size: cover;
  background-position: center;
  min-height: 500px; /* Ensures the image takes up enough space */
}

.cardContent {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .cardImage {
    min-height: 275px;
  }

  .cardContainer {
    flex-direction: column;
  }
}
