.cardContainer {
  display: flex;
  border-radius: 10px;
  border: 1px solid #d2d2d22b;

  box-shadow: 2px 4px 10px 0px #ff67a11a;
  padding: 20px;
  gap: 20px;
  width: 45%;
  max-height: 260px;
}

.left {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  justify-content: space-between;
  overflow: hidden;
}
.Viewprofile {
  color: #602ef4 !important;

  font-size: 11px;
  font-weight: 700;
  line-height: 13.41px;
  text-transform: inherit;
}

.right {
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.top {
  display: flex;

  justify-content: space-between;
  & h1 {
    font-size: 20px;
    font-weight: 600;
    line-height: 24.38px;
    color: #602ef4;
    margin-top: 0px;
  }
  & p {
    display: none;
  }
}

.rating {
  display: flex;

  & span {
    color: #7a53ff;

    font-size: 16px;
    font-weight: 400;
    line-height: 19.5px;
    text-align: left;
  }
}

.experience {
  font-size: 14px;
  font-weight: 400;
  line-height: 17.07px;

  color: #000000;
}

.middle {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.desc {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #777777;

  & span {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    color: #7a53ff;
  }
}
.bottom {
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  border-radius: 5px;
  text-align: center;
}

.bookButton {
  font-size: 10px !important;
  font-weight: 600 !important;
  /* line-height: 16.5px ; */
  color: #ffffff !important;
  background: #ff67a1 !important;
  margin: 20px 0px;
  /* text-transform: inherit; */
}
.labels {
  display: flex;
  gap: 10px;
  display: flex;
  flex-wrap: wrap;

  & span {
    background: #cc8aff33;
    text-align: center;
    padding: 5px;
    color: #602ef4;
    border-radius: 5px;
    display: inline-block;
    white-space: nowrap;
    font-size: 11px;
    font-weight: 400;
    line-height: 16.5px;
  }
}

.imageContainer {
  overflow: hidden;
  height: 90%;
  border-radius: 10px;
  & img {
    width: 100%;
  }
}
.hideContainer {
  display: none;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #d2d2d22b;
  box-shadow: 2px 4px 10px 0px #ff67a11a;
  padding: 20px;
  width: 272px;
}

.hideTop {
  display: flex;
  gap: 20px;
}

.profile {
  & img {
    width: 77px;
  }
}

.hideDesc {
  & h1 {
    font-size: 16px;
    font-weight: 600;
    line-height: 19.5px;
    text-align: left;
    color: #602ef4;
  }
  & p {
    font-size: 12px;
    font-weight: 400;
    line-height: 14.63px;
    text-align: left;
    color: #777777;
  }
}

.stars {
  display: flex;
  gap: 10px;

  & span {
    color: #7a53ff;
  }
}

.HideMiddle {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #777777;
  & span {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: #7a53ff;
  }
}
.hideButtons {
  display: flex;
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0px;
  & span {
    background: #cc8aff33;
    text-align: center;
    padding: 5px;
    color: #602ef4;
    border-radius: 5px;
    display: inline-block;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
  }
}
.hideBottom {
  display: flex;
  align-self: flex-end;
  justify-content: center;
  border-radius: 5px;
  text-align: center;
  margin: 10px;
}
.bookButton {
  font-size: 10px;
  font-weight: 600;
  line-height: 16.5px;
  color: #ffffff;
  background-color: #ff67a1;
  width: 244px;
  text-transform: inherit;
}

.hideView {
  display: flex;
  justify-content: center;
  & button {
    font-size: 14px;
    font-weight: 700;
    line-height: 17.07px;
    text-transform: inherit;
    color: #602ef4;
  }
}
.desc {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #777777;
}

@media only screen and (max-width: 992px) and (min-width: 768px) {
  .cardContainer {
    display: none;
  }
  .hideContainer {
    display: block;
  }
}
@media screen and (max-width: 768px) and (min-width: 320px) {
  .cardContainer {
    display: none;
  }
  .hideContainer {
    display: block;
  }
}
