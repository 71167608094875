.downloadeApp{
    display: flex;
    justify-content: center;
    align-items: center;
}

.containerLeft{
    /* width: 20%; */
    text-align: center;
}

.downloadbox{
    display: flex;
    gap: 10px;
    align-items: center;
    box-shadow: 2px 2px 15px 0px #00000040;
    margin-bottom: 20px;
    padding: 0 10px;
    box-sizing: border-box;

}

.boxText{

    & h5,h6{
        margin: 10px auto;
    }
}

.containeCenter{
    width: 10%;
}