.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.top {
  width: 100%;
  text-align: center;
  & h1 {
    font-size: 43px;
    font-weight: 800;
    line-height: 52.42px;
    color: #7a53ff;
  }
  & span {
    font-size: 43px;
    font-weight: 500;
    line-height: 52.42px;
  }
}
.middle {
  width: 100%;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  gap: 10%;

  & span {
    font-size: 22px;
    font-weight: 700;
    line-height: 26.82px;
    color: #a490fe;
  }
}

.bottom {
  background: linear-gradient(279.98deg, #ff67a1 -2.75%, #7a53ff 101.33%);
  border-radius: 20.87px;
  height: auto;
  width: 86%;
  margin: auto;
  padding: 45px 40px;
  display: flex;
  align-items: center;
  gap: 20px;
  overflow: hidden;
}

.right {
  width: 100%;
  display: flex;
  gap: 20px;
  align-items: center;
}
.left {
  width: 100%;
  padding: 0 20px;
}

.left h1 {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.5;
  color: #ffffff;
  overflow-wrap: break-word;
  margin-bottom: 20px;
}
.btn {
  text-align: center;
  padding: 10px 0px;
  & button {
    padding: 10px 0px;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.5px;
    background-color: #ffffff;
    text-transform: inherit;
    color: #602ef4;
    border: 1px solid #ffffff;
  }

  & button:hover {
    background-color: #ffffff;
  }
}
.correct {
  display: flex;
  gap: 10px;
  align-items: center;
  padding-bottom: 10px;
}

.correct p {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  color: #ffffff;
  margin: 0;
  overflow-wrap: break-word;
}

@media screen and (max-width: 768px) and (min-width: 320px) {
  .app {
    width: 90% !important;
  }
  .top {
    & h1 {
      font-size: 20px;
      span {
        font-size: 20px;
      }
    }
  }
  .middle {
    & span {
      font-size: 16px;
    }
  }
  .bottom {
    flex-direction: column;
    height: auto;
    padding: 20px;
  }
  .correct {
    align-items: center;
  }
  .right {
    width: 75%;
    flex-direction: column;
    align-items: center;
    gap: 0px;
    padding-bottom: 55px;
  }
  .right {
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding-bottom: 20px;
  }

  .left h1 {
    font-size: 18px !important;
    padding: 0 10px;
  }

  .correct p {
    font-size: 16px !important;
    line-height: 1.4;
  }

  .wrap {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
  }
}
