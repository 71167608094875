.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.top {
  width: 100%;
  text-align: center;
  & h1 {
    font-size: 43px;
    font-weight: 800;
    line-height: 52.42px;
    color: #7a53ff;

    & span {
      font-size: 43px;
      font-weight: 500;
      line-height: 52.42px;
    }
  }
}
.middle {
  width: 100%;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  gap: 10%;
  & span {
    font-size: 22px;
    font-weight: 700;
    line-height: 26.82px;
    color: #a490fe;
  }
}

.bottom {
  /* margin: 10px 0px; */
  background: linear-gradient(279.98deg, #ff67a1 -2.75%, #7a53ff 101.33%);
  border-radius: 20.87px;
  height: 400px;
  width: 86%;
  margin: auto;
  padding: 45px 40px;
  display: flex;
  align-items: center;
  gap: 50px;

}

.right {
  width: 60%;
  display: flex;
  gap: 20px !important;
}
.left {
  width: 40%;

  h1 {
    font-size: 20px;
    font-weight: 700;
    line-height: 24.38px;
    color: #ffffff;
  }
}
.btn {
  text-align: center;
  padding: 10px 0px;
  & button {
    padding: 10px 0px;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.5px;
    background-color: #ffffff;
    text-transform: inherit;
    color: #602ef4;
    border: 1px solid #ffffff;
  }
}
.correct {
  display: flex;
  gap: 10px;

  & p {
    font-size: 20px;
    font-weight: 600;
    line-height: 24.38px;
    color: #ffffff;
  }
}
.correct {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 768px) and (min-width: 320px) {
  .app {
    width: 90% !important;
  }
  .top {
    h1 {
      font-size: 20px;
      span {
        font-size: 20px;
      }
    }
  }
  .middle {
    span {
      font-size: 16px;
    }
  }
  .bottom {
    flex-direction: column;
    height: 100%;
    padding: 0px;
    .left {
      width: 100%;
      text-align: center;
    }
  }
  .correct {
    align-items: center;
  }
  .right {
    width: 75%;
    flex-direction: column;
    align-items: center;
    gap: 0px;
    padding-bottom: 55px;
  }
  .correct {
    gap: 0px !important;
    & p {
      font-size: 14px !important;
      text-wrap: nowrap !important;
      margin: 0px;
    }
  }
  .left {
    & h1 {
      font-size: 22px !important ;
      padding: 0px 10px;
    }
  }

  .wrap {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
  }
}
