.container {
  box-shadow: 1.3px 1.3px 5.19px 0px #00000040;
  /* border-top-left-radius: 9.73px;
  border-top-right-radius: 9.73px;
  border-bottom-left-radius: 9.73px;
  border-bottom-right-radius: 9.73px; */
  border-radius: 10px;
  border: none;
  background-color: #ffffff;
  /* width: calc(100% / 3) ; */
  width: 350px;
  overflow: hidden;
  cursor: pointer;
}
.top {
  width: 100%;
  max-height: 200px;
  overflow: hidden;
  & img {
    width: 100%;
  }
}

.bottom {
  padding: 10px;

  & h1 {
    font-size: 16px;
    font-weight: 700;
    line-height: 19.5px;
    color: #7a53ff;
    padding: 10px 0px;
    margin: 0px;
  }
  & p {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #000000;
    margin: 0px;
  }
}

@media screen and (max-width: 768px) and (min-width: 320px) {
  .container {
    width: 100%;
  }
}
