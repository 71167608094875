.mainHeader {
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.logo {
  width: 15%;
  padding-top: 10px;
}
.burgoNavigationBar {
  width: 50%;
  padding-right: 20px;
  white-space: nowrap;
}

.wrapperNavDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.navBarList {
  list-style: none;
  display: flex;
  justify-content: space-evenly;

  & .navBarList_item {
    position: relative;
    padding: 15px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 18px;
    font-weight: 500;
  }

  & .navBarList_item:hover {
    /* color: #7A53FF;
    font-weight: 600; */

    & .haveanimation {
      transform: rotate(180deg);
      /* animation: delay 4s ease-in-out; */
    }
  }
}
.haveanimation {
  transform: rotate(0deg); /* Starting position of the animation */
  transition: transform 0.2s ease-in-out; /* Smooth transition for rotation */
}

.navBarList {
  & .navBarList_item:hover {
    & .dropDownList {
      display: block;
    }
  }
}

.dropDownList {
  list-style: none;
  display: none;
  position: absolute;
  top: 40px;
  left: 0;
  background-color: #fff;
  width: auto;
  box-shadow: 0px 4px 10px 0px #00000040;
  padding: 0;
  border-radius: 10px;
  z-index: 100;
  overflow: hidden;
  & li {
    padding: 10px 10px;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    text-wrap: nowrap;
  }

  & li:hover {
    background-color: #7a53ff;
    color: #fff;
  }
}

.MobileNav {
  display: none;
}

.activeMenu {
  color: #7a53ff;
  font-weight: 600;
}

.buttonDiv {
  display: block;
  padding-top: 20px;
}

/* CSS for the Login button */
.loginButton {
  background: #ff4081;
  border-radius: 8px;
  box-shadow: #ff4081 0 10px 20px -10px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  font-family:
    Inter,
    Helvetica,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    NotoColorEmoji,
    "Noto Color Emoji",
    "Segoe UI Symbol",
    "Android Emoji",
    EmojiSymbols,
    -apple-system,
    system-ui,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    "Noto Sans",
    sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  outline: 0 solid transparent;
  padding: 10px 20px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  display: inline-block;
  text-align: center;
  border: 0;
}

/* Styles for the profile section */
.profileSection {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.profileIcon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ff4081; /* Match with the login button */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  color: #ffffff; /* White text */
  font-weight: 700; /* Bold text */
}

.profileName {
  color: #333; /* Darker text for the name */
  font-weight: 500;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .navBarList {
    display: none;
  }

  .MobileNav {
    display: block;
  }

  .logo {
    width: 180px;
    & img {
      width: 100%;
    }
  }
  .mobilenavTop {
    display: flex;
    justify-content: space-around;
    padding: 20px 10px;
    align-items: center;
    width: 300px;
  }

  .mobilelist {
    list-style: none;
    padding: 2px;
    border-radius: 10px;
  }

  .mobileListItem {
    font-size: 18px;
    font-weight: 600;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    animation-timing-function: 0.5 ease-in-out;

    & ul {
      display: none;
    }
  }

  .active {
    background-color: #ebe7ff;
    color: hsl(255, 90%, 57%);

    & .mobanimation {
      transform: rotate(180deg);
    }
  }
  .mobanimation {
    transform: rotate(0deg); /* Starting position of the animation */
    transition: transform 0.5s ease-in-out; /* Smooth transition for rotation */
  }

  .mobileinnerList {
    list-style: none;
    display: none;
  }

  .innerlistActive {
    display: block;
    transition: 0.5s ease-in-out;
  }
  .mobileinnerList_item {
    font-size: 16px;
    font-weight: 500;
    padding: 10px 20px;
  }
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .buttonDiv {
    display: none; /* Hide login button in mobile view */
  }

  /* Add styles for login button inside the side nav bar */
  .sideNavButtonDiv {
    display: block;
    padding: 20px;
    text-align: center; /* Center the login button */
  }

  .sideNavButtonDiv .loginButton {
    width: 100%; /* Make the button full width inside the nav */
    margin-top: 10px;
  }

  .profileSection {
    display: none;
  }
}
