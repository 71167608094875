/* Top section  */

.expertHeader {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 40px 0;
}

.containerLeft {
  width: 40%;
}

.bgHeart {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 45%;
}

.plane {
  position: absolute;
  z-index: -1;
  left: 90%;
  top: 90%;
}
.bgwithcolortext {
  & span {
    background-color: #fff4f4;
    color: #ff67a1;
    font-size: 22px;
    font-weight: 500;
    padding: 5px 30px;
  }
}

.allActionBtn {
  background-color: #ff67a1 !important;
  font-weight: 600 !important;
  flex: 0 1 auto;
  font-family: "Poppins", sans-serif !important;
  font-size: 30px !important;
  box-shadow: 0px 5px 7px 0 rgba(0, 0, 0, 0.3) !important;
  text-transform: none !important;
  border-radius: 10px !important;
}

.greyBtn {
  background-color: rgb(186, 184, 184) !important;
  font-weight: 600 !important;
  flex: 0 1 auto;
  font-family: "Poppins", sans-serif !important;
  font-size: 30px !important;
  box-shadow: 0px 5px 7px 0 rgba(0, 0, 0, 0.3) !important;
  text-transform: none !important;
  border-radius: 10px !important;
}

.allActionBtn :hover {
  box-shadow:
    0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.workshopName {
  color: #602ef4;
  font-size: 55px;
  font-weight: 800;
  margin-top: 2%;
  margin-bottom: 2%;
}

.expertCol {
  font-size: 22px;
  font-weight: 500;
  color: #602ef4;
}

.workshopDesc {
  font-size: 20px;
  font-weight: 500;
  color: #2d2d2d;
}

.containerRight {
  width: 35%;
}

.topRightImageContainer {
  position: relative;
  width: 616px; /* Match the SVG size */
  height: 617px; /* Match the SVG size */
}

.circularRing {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Ensure the ring is on top */
}

.circularImage {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%; /* Adjust size as needed to fit inside the ring */
  height: 100%; /* Adjust size as needed to fit inside the ring */
  transform: translate(-50%, -50%);
  object-fit: cover;
  border-radius: 50%;
  z-index: 0; /* Ensure the image is behind the ring */
}

.imgContainer {
  width: 100%;

  & img {
    width: 100%;
  }
}

/* child Beheviour */

.childbeheviour {
  display: flex;
  position: relative;
  width: 80%;
  margin: 80px auto;
  /* gap: 30px; */
  justify-content: space-evenly;
  align-items: center;
}

.childleftContainer {
  width: 23%;
  position: relative;
  border-radius: 10px;
  /* overflow: hidden; */
}

.childleftContainer::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #f9f2ff;
  position: absolute;
  z-index: -1;
  top: -15px;
  left: -15px;
  border-radius: 10px;
}

.childRightContainer {
  width: 40%;
}
.innerVideoDisplay {
  width: 100% !important;
  height: 100% !important;
}

.secondheading {
  font-size: 44px;
  font-weight: 600;
  color: #7a53ff;
}

.ChildDescription {
  font-size: 22px;
  font-weight: 400;
  color: #2d2d2d;
}

/* counterBox section  */

.countersboxContainer {
  width: 88%;
  margin: 40px auto;
}

.counterBox {
  background: linear-gradient(92.66deg, #602ef4 1.8%, #b057da 102.02%);
  display: flex;
  justify-content: space-around;
  border-radius: 10px;
}

.cBox {
  color: #fff;
  text-align: center;

  & h2 {
    font-size: 55px;
    font-weight: 700;
    margin: 10px 0;
  }

  & p {
    font-size: 22px;
    font-weight: 400;
  }
}

/* Work Shop */

.workShopsContainer {
  background-image: url("../../Assets/course_page/expertBGTop.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 330px; /* Adjust based on your design needs */
}

.WSContainer {
  width: 1400px;
  margin: 0 auto;
  padding-top: 210px;
  padding-bottom: 20px;
}

.wstopText {
  & span {
    background-color: #a490fe;
    color: #ffffff;
    font-size: 22px;
    font-weight: 500;
    padding: 5px 10px;
  }
}

.shopsName {
  font-size: 55px;
  font-weight: 600;
  color: #fff;
}

.workSContainer {
  font-size: 22px;
  font-weight: 400;
  color: #ffffff;
  text-align: justify;
}

.workSslider {
  background-image: url("../../Assets/expert_page/workshopbg_2.svg");
  padding-bottom: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 200px; /* Adjust based on your design needs */
  width: 100%;
}

.InnerSliderCon {
  width: 1400px;
  margin: 0 auto;
  /* display: flex; */
  gap: 10px;
}

/* what client says */

.clientReviews {
  width: 1400px;
  margin: 0 auto;
}

.CRHeading {
  font-size: 40px;
  font-weight: 800;
  color: #602ef4;
}

/* Specialities */

.specialityContainer {
  width: 90%;
  margin: 40px auto;
}

.spHeading {
  font-size: 55px;
  font-weight: 600;
  color: #7a53ff;
}

.spDesc {
  font-size: 22px;
  font-weight: 400;
  color: #7a53ff;
}

/* Blogs */

.BlogContainer {
  width: 1400px;
  margin: 0 auto;
}
.blogheading {
  font-size: 44px;
  font-weight: 800;
  color: #602ef4;
}

.onlyforbg {
  background: linear-gradient(
    180deg,
    rgba(244, 229, 255, 0.6) 0%,
    rgba(244, 229, 255, 0) 100%
  );
}

.dateContainer {
  display: flex;
  /* border-width: 1px;
    border-color: #2d2d2d;
    border-style: solid; */
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
}

.dateIcon .clockIcon {
  height: 40px;
}

.dateIconContainter {
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.clockIconContainer {
  display: flex;
  align-items: center;
}

.dateIconBanner .clockIconBanner {
  height: 40px;
}

.dateIconContainterBanner {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 30px;
  color: white; /* This will affect the SVGs if they use `currentColor` */
  font-size: 3em; /* Adjust the size as needed */
  font-weight: bold;
  margin-left: 10px; /* Adjust spacing as needed */
}

.clockIconContainerBanner {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white; /* This will affect the SVGs if they use `currentColor` */
  font-size: 3em; /* Adjust the size as needed */
  font-weight: bold;
  margin-left: 25px; /* Adjust spacing as needed */
}

.dateContainerBanner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 0px;
}

.specialPrice {
  font-weight: bold;
  font-size: 17px;
}

.buttonChipContainer {
  display: inline-block;
  position: relative;
}

.buttonChipContainerCenter {
  display: inline-block;
  position: relative;
}

.buttonContainer {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.buttonContainerCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.allActionBtnCenter {
  background-color: #ff67a1 !important;
  font-weight: 600 !important;
  flex: 0rem 1 auto;
  font-family: "Poppins", sans-serif !important;
  font-size: 50px !important;
  box-shadow: 0px 5px 7px 0 rgba(0, 0, 0, 0.3) !important;
  text-transform: none !important;
  border-radius: 10px !important;
}

.seatsChip {
  position: absolute;
  top: -15px;
  right: -10px;
  background-color: white;
  color: #333;
  font-size: 12px;
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 15px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  white-space: nowrap;
  font-family: "Poppins", sans-serif;
}

.allActionBtnCenterGrey {
  background-color: rgb(186, 184, 184) !important;
  font-weight: 600 !important;
  flex: 0rem 1 auto;
  font-family: "Poppins", sans-serif !important;
  font-size: 50px !important;
  box-shadow: 0px 5px 7px 0 rgba(0, 0, 0, 0.3) !important;
  text-transform: none !important;
  border-radius: 10px !important;
}

.allActionBtnCenter :hover {
  box-shadow:
    0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19) !important;
}

.specialPriceCenter {
  font-weight: bold;
  font-size: 20px;
}

/* Features.module.css */
.workshopHelpContainer {
  display: flex;
  flex-direction: column;
  border-width: 1px;
  padding-left: 6%;
}

.features {
  display: flex;
  gap: 20px;
  overflow-x: auto;
  padding: 10px 0;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.features::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}

.feature {
  min-width: 150px; /* Adjusted for better visual spacing */
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.feature p {
  margin: 0;
  padding: 0;
  font-size: 16px; /* Adjust font size as needed */
  line-height: 1.4; /* Good for readability */
  text-align: center; /* Centers the text, if that is desired */
  word-wrap: break-word; /* Essential for wrapping long words */
  white-space: normal; /* Default value, but good to ensure it's set if needed */
  max-width: 90%; /* Limits width to ensure padding/margins are considered */
  font-weight: 500;
}

.icon {
  width: 75px; /* Adjust size as needed */
  height: 75px;
  background-color: #e1dbfb; /* Light purple */
  border-radius: 50%; /* Circular shape */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.icon img {
  width: 100%; /* Adjust the icon size within the circle */
  height: auto;
}

/* Workshop.module.css */
.aboutWorkshop {
  display: flex;
  align-items: center; /* Vertically aligns the items in the container */
  justify-content: flex-start; /* Spreads the content and image apart */
  padding: 4% 0 2% 6%;
  gap: 20px; /* Adds space between text content and image */
}

.textContent {
  flex: 1; /* Takes up half of the container space */
  max-width: 600px; /* Limits width of the text content */
  padding-left: 12%;
}

.imageContent {
  position: relative;
  flex: 1; /* Takes up the remaining half */
  max-width: 600px;
}

.imageContent img {
  width: 100%;
  height: auto; /* Maintains aspect ratio */
  display: block; /* Removes bottom space/gap */
  border-radius: 15px;
}

.pointsContainer {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  font-size: 23px;
  font-weight: 500;
}

.column {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.points {
  flex: 1;
  min-width: 250px; /* Prevents the columns from becoming too narrow */
}

.points p {
  margin: 12px;
  padding: 0;
}

.bookSessionButton {
  background-color: magenta; /* Bright color for visibility */
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.workshopLearning {
  padding: 2% 6%;
}

.workshopCovers {
  padding: 0% 6% 3%;
  font-size: larger;
}

.workshopCovers p {
  padding-left: 9px;
}

.workshopCovers h2 {
  color: #5c4be6;
}

.workshopCoversSection {
  margin: 10px 0;
}

.workshopCoversSection span {
  font-size: 20px;
  font-weight: 600;
}

.workshopCoversSectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
}

.workshopCoversSectionContent {
  padding: 10px;
}

.workshopCoversSectionContent p {
  margin: 0px;
}

.showMoreButton {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #b3a0ff;
  color: white;
  border: none;
  cursor: pointer;
  text-align: center;
}

.lecture {
  padding: 5px 0;
}

.lectureHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lectureTitle {
  flex: 1;
}

.lectureDuration {
  display: block;
  margin-top: 5px;
  color: #666;
}

.workshopChapter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.workshopChapter h4 {
  margin: 10px;
}

.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popupContent {
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 80%;
}

.videoPlayer {
  width: 100%;
  height: auto;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #f44336;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
}

.closeButton:hover {
  background-color: #d32f2f;
}

.faq {
  width: 1400px;
  margin: 0 auto;
}

.dateText,
.timeText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  /* top section  */
  .expertHeader {
    display: block;
    width: 100%;
  }

  .containerLeft {
    width: 100%;
    text-align: center;
  }

  .containerRight {
    /* width: 75%; */
    width: 90%;
    margin: 30px auto;
    display: flex;
    justify-content: center;
  }

  .bgwithcolortext {
    & span {
      font-size: 14px;
      font-weight: 500;
      padding: 5px 30px;
    }
  }

  .expertName {
    font-size: 25px;
  }

  .expertCol {
    font-size: 14px;
    font-weight: 600;
  }

  .expertDesc {
    font-size: 12px;
    font-weight: 400;
  }

  .bgHeart {
    width: 100px;
    top: 35%;
    right: 0;

    & img {
      width: 100%;
    }
  }

  /* child beheviour */

  .childbeheviour {
    display: block;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }

  .childleftContainer {
    width: 100%;
  }

  .childRightContainer {
    width: 100%;
    margin-top: 50px;
  }

  .secondheading {
    font-size: 20px;
    font-weight: 600;
  }
  .ChildDescription {
    font-size: 12px;
    font-weight: 400;
  }

  /* counter box */

  .countersboxContainer {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }

  .counterBox {
    flex-wrap: wrap;
  }

  .cBox {
    width: 150px;

    & h2 {
      font-size: 30px;
      font-weight: 700;
    }

    & p {
      font-size: 16px;
      font-weight: 400;
    }
  }

  /* workshop  */
  .workShopsContainer {
    background-image: url("../../Assets/course_page/expertBGTop.svg");
  }

  .WSContainer {
    width: 100%;
    padding: 80px 20px 10px;
    box-sizing: border-box;
    margin-bottom: -3px;
  }

  .InnerSliderCon {
    width: 100%;
  }

  .wstopText {
    font-size: 18px;
    font-weight: 500;
  }

  .shopsName {
    font-size: 20px;
    font-weight: 600;
  }

  .workSContainer {
    font-size: 14px;
    font-weight: 400;
  }

  .workSslider {
    background-image: url("../../Assets/expert_page/childbgmbottom.svg");
    min-height: 300px; /* Adjust based on your design needs */
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  /* client reviews */

  .clientReviews {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }

  .CRHeading {
    font-size: 30px;
    font-weight: 800;
    text-align: center;
  }

  /* specialities */

  .wstopText {
    font-size: 16px;
  }

  .spHeading {
    font-size: 20px;
  }

  .spDesc {
    font-size: 14px;
    text-align: justify;
  }

  .spSliderCOntainer {
    width: 100%;
  }
  /* blog */

  .BlogContainer {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }

  .blogheading {
    font-size: 20px;
  }

  .dateContainer {
    flex-direction: column;
    justify-content: center;
  }

  .dateIconContainter {
    margin-right: 0px;
  }

  .dateIcon {
    height: 35px;
  }

  .dateIconContainterBanner {
    font-size: 1.5em;
    margin-left: 0px;
    margin-right: 0px;
  }

  .dateContainerBanner {
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0px;
  }

  .clockIconContainerBanner {
    font-size: 1.5em;
    margin-left: 0px;
    margin-top: 10px;
  }

  .clockIcon {
    height: 35px;
  }

  .aboutWorkshop {
    flex-direction: column; /* Stacks the elements vertically on smaller screens */
    padding: 4% 0 2% 0%;
  }

  .textContent,
  .imageContent {
    max-width: 100%; /* Allows content to fill the container on smaller screens */
    align-items: center;
    display: flex;
    flex-flow: column;
    padding-left: 0;
  }

  .playButton {
    font-size: 16px; /* Smaller button on smaller screens */
  }

  .feature {
    padding: 20px 0 20px 20px;
  }

  .pointsContainer {
    flex-direction: column;
  }

  .pointsMargin {
    margin-top: 0;
  }

  .workshopLearning {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .workshopDesc {
    font-size: 14px;
    text-align: center;
    padding: 0 4% 0 4%;
  }

  .workshopName {
    font-size: 35px;
  }

  .bgHeart {
    display: none;
  }

  .plane {
    display: none;
  }

  .pointsContainer {
    font-size: 18px;
  }

  .allActionBtnCenter {
    padding: 6px 16px !important;
    font-size: 24px !important;
  }

  .specialPriceCenter {
    font-size: 13px;
  }

  .workShopsContainer {
    min-height: 100px;
  }

  .allActionBtn {
    padding: 6px 16px !important;
    font-size: 24px !important;
  }

  .specialPrice {
    font-size: 13px;
  }

  .topRightImageContainer {
    width: 325px;
    height: 325px;
  }

  .faq {
    width: auto;
    margin: 0 auto;
  }
}
