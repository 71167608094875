.container {
  display: flex;
  position: relative;
  padding: 30px 0px;
}
.left {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 10%;
  gap: 20px;

  bottom: 30%;
  & h1 {
    color: #ffffff;
    font-size: 40px;
    font-weight: 700;
  }
}
.button {
  border-radius: 8px;
  background-color: #7a53ff !important;
  color: #ffffff;
  text-transform: inherit;

  width: 511px;
  border: none;
  padding: 20px !important;
}
.button:hover {
  background-color: #7a53ff;
}
.right {
  width: 100%;
 & img {
    width: 100%;
  }
}
.doctorMobile {
  display: none;
}
@media only screen and (max-width: 992px) and (min-width: 768px) {
  .doctor {
    display: none;
  }
  .doctorMobile {
    display: block;
  }

  .left {
    width: 100%;
    text-align: center;
    left: 0px !important;
    bottom: 15%;
  &  h1 {
      font-size: 25px;
      font-weight: 700;
      line-height: 30.48px;
    }
  }
  .button {
    padding: 15px;
    width: 200px;
  }
}
@media screen and (max-width: 768px) and (min-width: 320px) {
  .doctor {
    display: none;
  }
  .doctorMobile {
    display: block;
  }

  .left {
    width: 100%;
    text-align: center;
    left: 0px !important;
    bottom: 15%;
    gap: 10px;
   & h1 {
      font-size: 25px;
      font-weight: 700;
      line-height: 30.48px;
    }
  }
  .button {
    padding: 10px;
    width: 146px;
  }
}
