.container {
  position: relative;


}
.offerbutton {
  background-color: #a490fe !important;
  border: none !important;
  border-radius: 3.92px;
  text-transform: inherit;
  color: #ffffff !important;

  display: flex;
  gap: 5px;
}
.head {
  font-size: 30.59px;
  font-weight: 700;
  line-height: 45.88px;
  color: #ffffff;
}
.cardImg {
  width: 240px;
  position: relative;
  margin: 0px auto;
  img {
    width: 100%;
  }
}
.options {
  position: absolute;
  bottom: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0px auto;
  width: 100%;
  text-align: center;
}
@media screen and (max-width: 768px) and (min-width: 320px) {
 
.cardImg{
  width: 215px;
}


}