/* SessionsConducted.css */
.sessions-conducted-container {
  margin-top: 30px;
  padding-left: 20px;
}

.header-order-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-order-details h2 {
  font-size: 1.6rem;
  font-weight: bold;
}

.view-history-order-details {
  color: #7045fb;
  text-decoration: none;
  font-size: 1rem;
}

.sessions-grid-order-details {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.session-card-order-details {
  border: 2px solid #e0e0e0;
  border-radius: 10px;
  padding: 15px;
  text-align: center;
  transition: all 0.3s ease;
}

.session-card-order-details:hover {
  border-color: #7045fb;
}

.session-number-order-details {
  font-weight: bold;
  font-size: 1.1rem;
}

.session-date-order-details {
  font-weight: bold;
  margin: 10px 0;
  font-size: 1rem;
}

.session-status-order-details {
  font-weight: bold;
  font-size: 0.9rem;
  margin: 10px 0;
}

.session-status-order-details.completed {
  color: green;
}

.session-status-order-details.cancelled {
  color: red;
}

.session-status-order-details.booked {
  color: #7045fb;
}

.sessions-grid-order-details {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.session-card-order-details {
  border: 1px solid #e3e3e3;
  padding: 10px;
  border-radius: 10px;
  width: 150px;
  text-align: center;
}

.view-history-order-details {
  color: #7045fb;
  text-decoration: none;
}

.session-conducted-join-button {
  margin-top: 1px;
  background-color: #ff67a1 !important;
  color: white;
  width: 60%;
  margin: 0px auto; /* Center the button horizontally */
}
