/* Container styling for desktop and mobile banners */
.desktopContainer,
.mobileContainer {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60vh; /* Reduced height to 60% of the viewport height */
  position: relative;
  overflow: hidden;
}

.desktopContainer {
  display: flex;
}

.mobileContainer {
  display: none; /* Hidden by default */
}

.timer {
  font-size: 18px;
  font-weight: 600;
  color: #ff0000; /* Example color: red */
  margin-bottom: 10px; /* Space between timer and button */
}

@media screen and (max-width: 767px) {
  .desktopContainer {
    display: none;
  }

  .mobileContainer {
    display: block;
  }
}

/* Image styling for non-image-only content */
.right img {
  width: 50%; /* Adjust the size as needed */
  height: auto;
  object-fit: cover;
  border-radius: 50%; /* Make the image circular */
}

/* Full-screen image styling for image-only banners */
.fullScreenImage img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the entire area */
  border-radius: 0; /* No border radius for full-screen image */
}

.sliderImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.left {
  width: 30%;
  display: flex;
  flex-direction: column;
  padding-left: 10%;
}

.right {
  display: flex;
  justify-content: center;
  padding-left: 5%;
}

/* Heading and paragraph styling */
.heading {
  font-size: 60px;
  font-weight: 700;
  line-height: 73.14px;
  color: #7a53ff;
}

.para1 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #454545;
}

.para2 {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #454545;
  margin: 0;
  padding-bottom: 30px;
}

/* Button styling */
.button {
  background-color: #ff67a1 !important;
  font-weight: 600 !important;
  flex: 0 1 auto;
  font-family: "Poppins", sans-serif !important;
  font-size: 30px !important;
  box-shadow: 0px 5px 7px 0 rgba(0, 0, 0, 0.3) !important;
  text-transform: none !important;
  border-radius: 10px !important;
  width: 50%;
}

/* Promotion Badge styling */
.promotionBadge {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: #ff0000;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
}

/* Overlay content styling */
.contentOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 20px;
}

.contentOverlay .heading,
.contentOverlay .para1,
.contentOverlay .button {
  margin-bottom: 10px;
}

/* Responsive styling for tablets */
@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .left {
    width: 50%;
  }

  .heading {
    font-size: 50px;
    line-height: 60px;
  }

  .right {
    width: 38%;
  }

  .right img {
    width: 100%;
    height: auto;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1799px) {
  .left {
    width: 50%;
  }

  .heading {
    font-size: 40px;
    line-height: 50px;
  }

  .button {
    width: 60%;
  }
}

/* Responsive styling for mobile */
@media screen and (max-width: 767px) {
  .heading {
    font-size: 36px;
    line-height: 44px;
    color: white;
  }

  .para1 {
    font-size: 14px;
    line-height: 20px;
    color: white;
  }

  .button {
    font-size: 16px !important;
    padding: 8px 20px !important;
    width: 50%;
  }

  .mobileContainer {
    height: 45vh;
  }
}
