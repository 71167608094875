.my-learning-container {
  width: 90%;
  margin: 0 auto;
}

.my-learning-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.sort-filter {
  display: flex;
  gap: 20px;
}

.sort-filter select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  border-color: #7a53ff;
}

.sort-filter-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.sort-filter-container p {
  margin: 0;
}

.search {
  margin-left: auto;
  align-self: flex-end;
}

.search input {
  padding: 10px;
  width: 200px;
  border: 1px solid #ccc;
  border-radius: 4px;
  border-color: #7a53ff;
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.mylearning-card {
  display: flex;
  flex-direction: column; /* Stack content vertically */
  justify-content: space-between; /* Push content to fill available space */
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 100%; /* Ensure consistent card height */
  padding: 15px;
  box-sizing: border-box;
  position: relative;
}

.card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  position: relative;
}

.card-content {
  margin-top: 10px;
  text-align: left;
}

.card-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
  text-align: left;
  padding-left: 3%;
}

.card-expert {
  margin-top: 2%;
  margin-bottom: 3%;
}

.card-category {
  margin: 0;
}

.card-expert,
.card-category {
  font-size: 14px;
  color: gray;
  text-align: left;
  padding-left: 3%;
}

/* Chip styles */
.mylearning-card-chip {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: white;
  color: black;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.cmylearning-card-chip.on-demand {
  background-color: #7a53ff;
  color: white;
}

.mylearning-card-chip.live-schedule {
  background-color: #ff4757;
  color: white;
  border-color: #ff4757;
}

.pagination {
  margin-top: 20px;
  text-align: center;
}

.pagination button {
  padding: 10px 15px;
  margin: 0 5px;
  background-color: #eee;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.pagination button.active {
  background-color: #7a53ff;
  color: white;
}

.join-button {
  background-color: #ff67a1;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%; /* Full width for mobile responsiveness */
  text-align: center;
  margin-top: 15px;
  font-weight: 500;
}

/* Disabled state */
.join-button.disabled,
.join-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.join-button:hover:not(.disabled) {
  background-color: #fc488d;
}

@media (max-width: 768px) {
  .controls {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }

  .sort-filter {
    flex-direction: column;
    width: 100%;
  }

  .search {
    width: 100%;
    margin-left: 0;
  }

  .search input {
    width: 95%;
  }

  .sort-filter-container {
    width: 100%;
  }

  .sort-filter select {
    width: 100%;
  }
  .cards-container {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .cards-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
