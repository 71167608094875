.container {
  background: linear-gradient(109.86deg, #602ef4 0%, #f887b2 98.18%);
  display: flex;
  box-shadow: 10px 10px 15px 0px #00000040;
  border-radius: 10px;
}
.BootstrapDialog {
  width: 1100px;
}

.para {
  font-size: 20px;
  font-weight: 500;
  line-height: 24.38px;
  color: #ffffff;

  & span {
    font-size: 20px;
    font-weight: 700;
    line-height: 24.38px;
  }
}
.btn {
  font-size: 18px;
  font-weight: 600;
  line-height: 21.94px;
  color: #ff67a1;
  border-radius: 10px;
  border: none;
  padding: 15px;
}
.pickBaby {
  display: block;
  & img {
    width: 100%;
  }
}
.pickBabyMobile {
  display: none;
  width: 100%;
  & img {
    width: 100%;
  }
}
.back {
  padding: 10px;
  cursor: pointer;
}
.right {
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 20px;
}

.bottom {
  display: flex;
  align-items: center;
  padding: 30px 0px;
}
.top {
  text-align: center;
}

.bottomright {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px 20px;
  width: 180px;
  & img {
    width: 100%;
  }
}
.myBurgologo{
    & img{
        width: 100%;
    }
}

@media screen and (max-width: 768px) and (min-width: 320px) {
  .container {
  }
  .BootstrapDialog {
    width: 90%;
    position: relative;
  }
  .pickBabyMobile {
    display: block;
  }
  .pickBaby {
    display: none;
  }
  .right {
    position: absolute !important;
    bottom: 10px;
    margin: auto;
    width: 100%;
    gap: 0px;
  }
  .myBurgologo{
   margin: auto;
    width: 200px;
  }
  .para{
    font-size: 15px !important;
    text-align: center;
    & span{
        font-size: 15px !important;
        text-align: center;
    }
  }
  .bottomright{
    width: 140px;
  }
  .bottomleft{
    width: 110px;
    & img{
        width: 100%;
    }
  }
  .bottom{
   
 justify-content: center;
  }
}
