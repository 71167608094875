.login-page-container {
  display: flex;
  min-height: 100vh;
  background-color: #f5f5f5;
}

.left-section {
  width: 60%;
  background-color: #a74adb;
  background-image: url("../../Assets/login/loginbackground.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.left-section h2 {
  font-family: "Poppins", sans-serif;
  color: white;
  margin-top: 0;
  margin-bottom: 30px;
  font-size: large;
  font-weight: 500;
}

.logo {
  max-width: 300px;
  margin-bottom: 10px;
}

.phone-mockup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.phone-mockup {
  max-width: 500px;
  width: 100%;
  height: auto;
}

.app-store-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.app-store-button {
  max-width: 150px;
  cursor: pointer;
}

.right-section {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #ffffff;
  flex-direction: column;
  max-height: 80vh;
  overflow-y: auto;
}

@media only screen and (max-width: 767px) {
  .login-page-container {
    flex-direction: column;
  }

  .left-section {
    display: none;
  }

  .right-section {
    width: 100%;
    background-color: #f5f5f5;
    padding: 0;
    max-height: calc(100vh - 50px);
  }
}
