

.desc{
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    color: #7a53ff;
    border: none;
    background-color: white;
    cursor: pointer;
}
.para{
font-size: 12px;
font-weight: 400;
line-height: 18px;
color: #777777;
}