.workshop-feedback-page {
  display: flex;
  flex-direction: column;
  min-height: 75vh;
}

.workshop-feedback-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.workshop-feedback-card {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
  padding: 20px;
  max-width: 400px;
  width: 100%;
}
