.desktop {
  display: block;
  width: 49%;
}

.types {
  border: 1px solid #d2d2d2;
  background-color: #ffffff;
  width: 98%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
}
.left {
  display: block;
  padding: 5px;
  img {
    width: 100%;
  }
}
.mobile {
  display: none;
}
.middle {
  width: 77%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.typesp {
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  color: #000000;
  margin: 0px;
}
.typesh1 {
  font-size: 20px;
  font-weight: 700;
  line-height: 24.38px;
  margin: 0px;
  padding: 10px 0px;
}
.right {
  padding: 20px;
  width: 20%;
}
.paras {
  width: 80%;
}
@media screen and (max-width: 768px) and (min-width: 320px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
  .types {
    width: 95%;
    margin: auto;
    flex-direction: column;
  }

  .left {
    width: 95%;
    padding-top: 15px;
  }
  .middle {
    width: 95%;
    align-items: end;
    padding: 10px 0px;
  }
  .right {
    width: auto;
    padding: 0px;
  }
  .typesp {
    font-size: 12px;
    line-height: 14.63px;
  }
  .typesh1 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 1140px) and (min-width: 768px) {


  .desktop{
    width: 48%;
  }
}