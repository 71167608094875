.container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;
  & img {
  }
}
.info {
  align-self: flex-end;
  & span {
    font-size: 44px;
    font-weight: 600;
    line-height: 53.64px;
    color: #ff67a1;
  }

  & p {
    font-size: 18px;
    font-weight: 600;
    line-height: 21.94px;
    margin: 0px;
    color: #a490fe;
    text-wrap: nowrap;
  }
}
@media only screen and (max-width: 992px) and (min-width: 768px) {
  .info {
    & span {
      font-size: 30px !important;
    }
    & p {
      font-size: 15px !important;
      line-height: 5.94px !important;
    }
  }
}

@media screen and (max-width: 768px) and (min-width: 320px) {
  .container {
    width: 50%;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 20px 0px;

    & img {
      padding: 5px;
    }
  }
  .info {
    align-self: center;

    & span {
      font-size: 35px;
    }
  }
}
