.workshop-card {
  width: 300px;
  margin: 0 auto;
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.workshop-card h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

.workshop-card h3 {
  font-size: 16px;
  color: #7a53ff;
  margin-bottom: 20px;
}

.stars {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.star {
  cursor: pointer;
  transition: fill 0.2s;
  padding: 10px;
}

.star.selected {
  fill: #7a53ff;
}

.chips {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
  color: #7a53ff;
}

button {
  color: #7a53ff;
}

.chip {
  padding: 5px 10px;
  border: 1px solid #7a53ff;
  background-color: transparent;
  border-radius: 10px;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.chip.selected {
  background-color: #7a53ff;
  color: white;
}

.review p {
  margin-bottom: 10px;
  font-size: 14px;
}

textarea {
  width: 100%;
  height: 80px;
  border: 1px solid #7a53ff;
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 20px;
}

.submit-button {
  background-color: #ff67a1;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #e04d89;
}
