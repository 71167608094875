.banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #6200ea;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  z-index: 1000;
}

.banner span {
  color: white;
  font-size: 20px;
}

.banner-content {
  display: flex;
  align-items: center;
  gap: 15px;
}

.offer-text {
  font-size: 1.2rem;
  font-weight: bold;
}

.timer {
  display: flex;
  gap: 5px;
}

.time {
  background-color: #b388ff;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
}

.register-button {
  background-color: #ff4081;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
}

.register-button:hover {
  background-color: #ff79b0;
}

.special-offer {
  font-size: 0.5rem;
  color: #ffccff;
}

.seatsChip {
  position: absolute;
  top: -15px;
  right: -10px;
  background-color: white;
  color: #333;
  font-size: 10px;
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 15px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  white-space: nowrap;
  font-family: "Poppins", sans-serif;
}

.buttonChipContainer {
  display: inline-block;
  position: relative;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .banner-content {
    flex-direction: column;
    gap: 10px;
  }

  .register-button {
    width: 100%;
    text-align: center;
  }

  .special-offer {
    display: none;
  }

  .offer-text {
    display: none;
  }
}
