.top {
  width: 100%;
  text-align: center;
  & h1 {
    font-size: 43px;
    font-weight: 800;
    line-height: 52.42px;
    color: #7a53ff;
  }
  & span {
    font-size: 43px;
    font-weight: 500;
    line-height: 52.42px;
    color: #7a53ff;
  }
}
.names {
  width: 100%;
  justify-content: center;
}
.tabsName {
  font-size: 22px !important;
}
@media screen and (max-width: 768px) and (min-width: 320px) {
  .top {
    & h1 {
      font-size: 20px;
    }
    & span {
      font-size: 20px;
    }
  }
  .tabsName {
    font-size: 16px !important;
  }
}
