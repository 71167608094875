.container {
}
.mental {
  font-size: 44px;
  font-weight: 800;
  line-height: 53.64px;
  color: #602ef4;
  width: 100%;
  text-align: center;
  padding-top: 60px;
  margin: 0px;
  box-sizing: border-box;
}

.physical {
  width: 100%;
  text-align: center;
  font-size: 44px;
  font-weight: 800;
  line-height: 53.64px;
  color: #602ef4;
  padding: 30px 0px;
}
.services {
  width: 94%;
  margin: auto;
  .container {
    .top {
      display: none;
    }
  }
}
.blogCards {
  width: 90%;
  margin: auto;
  padding: 50px 0px;
  box-sizing: border-box;
}
.blogs {
  font-size: 30px;
  font-weight: 800;
  line-height: 36.57px;
  width: 100%;
  text-align: center;
}
.desktopTypesContainer {
  width: 80%;
  margin: auto;
  padding: 50px 0px;
  box-sizing: border-box;
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
}
.mobileTypesContainer {
  display: none;
  padding: 10px 0px;
}
.ourSkills {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  width: 90%;
  margin: auto;
  padding: 50px 0px;
}
.skills {
  width: 100%;
  text-align: center;
  font-size: 44px;
  font-weight: 800;
  line-height: 53.64px;
  padding: 30px 0px;
  color: #000000;
}
.cards {
  width: 80%;
  margin: auto;
}
.servicesp {
  font-size: 30px;
  font-weight: 800;
  line-height: 36.57px;
  color: #8b8b8b;
  margin-bottom: 0px;
  width: 96%;
  margin-left: auto;
}
@media screen and (max-width: 768px) and (min-width: 320px) {
  .desktopTypesContainer {
    display: none;
  }

  .mobileTypesContainer {
    display: block;
  }
  .mental {
    font-size: 20px;
    padding-top: 25px;
  }
  .physical {
    font-size: 20px;
  }
  .cards {
    width: 90%;
  }
  .servicesp {
    font-size: 18px !important;
  }
}
.blogCards {
  padding-bottom: 20px;
}

.heading {
  font-size: 44px;
  font-weight: 800;
  line-height: 53.64px;
  color: #000000;
  width: 100%;
  text-align: center;
}

@media only screen and (max-width: 1140px) and (min-width: 768px) {
  .desktopTypesContainer {
    width: 90%;
  }
}
