.cardConatiner {
  width: 250px !important;
  text-align: center;
}

.descript {
  font-weight: 500;
}

.imgConatiner {
  width: 100%;

  & img {
    width: 100%;
  }
}
