/* Main Container */
.workshop-purchase-details-container {
  display: flex;
  gap: 20px;
  padding: 20px;
}

/* Left Section */
.workshop-purchase-details-left {
  flex: 0 0 25%; /* Take up 25% of the container */
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.workshop-purchase-details-image-container {
  position: relative;
}

.workshop-purchase-details-image {
  width: 100%; /* Set the image width to fill the container */
  max-height: 180px; /* Limit the height of the image */
  object-fit: cover; /* Ensure the image fits nicely */
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.workshop-purchase-details-title {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 1.2rem;
  font-weight: bold;
}

.workshop-description {
  font-size: 1rem;
  color: #555;
}

.expertImageSection {
  flex: 1;
  text-align: center; /* Centers the image and the text in the section */
  position: relative;
}

.svgBackground {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 60%; /* Adjust the width as needed */
  z-index: 0; /* Make sure the background stays behind the expert image */
}

.expertImage {
  width: 60%; /* Adjust as needed */
  border-radius: 50%;
  position: relative;
  z-index: 1; /* Ensure image is above the background */
}

.expertName {
  margin-top: 10px;
  font-size: 1.4rem;
  font-weight: bold;
}

.expertTagline {
  font-size: 1rem;
  color: #555;
}

.feedback-button {
  background-color: #ff67a1;
  color: white;
  width: 60%;
  margin: 0 auto; /* Center the button horizontally */
}

/* Right Section */
.workshop-purchase-details-right {
  flex: 0 0 75%; /* Take up 75% of the container */
}

/* Info Sections */
.booking-info,
.payment-info,
.price-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.booking-info div,
.payment-info div,
.price-info div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.booking-info span,
.payment-info span,
.price-info span {
  font-size: 0.9rem;
  color: #333;
}

.grand-total-label {
  color: #333;
  font-weight: bold;
}

.grand-total-price {
  color: #7a53ff !important;
  font-weight: bold;
}

/* Accordion Styling */
.MuiAccordion-root {
  border-radius: 8px;
  margin-bottom: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.MuiAccordionSummary-root {
  background-color: #f1f1f1;
  border-radius: 8px;
}

.MuiAccordionDetails-root {
  background-color: #fff;
  border-radius: 8px;
}

/* Payment Status Colors */
.grand-payment-status.paid {
  color: green;
}

.grand-payment-status.failed {
  color: red;
}

.grand-payment-status.pending {
  color: orange;
}

/* Mobile view */
@media only screen and (max-width: 767px) {
  .workshop-purchase-details-container {
    flex-direction: column;
  }

  .workshop-purchase-details-left {
    flex: 0 0 100%; /* Full width for mobile view */
  }

  .workshop-purchase-details-right {
    flex: 0 0 100%; /* Full width for mobile view */
  }

  .feedback-button {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0;
    z-index: 1000;
    padding: 15px;
    border-radius: 0;
    text-align: center;
    font-size: 16px;
  }
}
